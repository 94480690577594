import { isNode } from 'client/utils/environment';

export function isGpuDisabled() {
  let gpuDisabled;
  try {
    const canvas = document.createElement('canvas');
    gpuDisabled = !(canvas.getContext('webgl') || canvas.getContext('experimental-webgl'));
  } catch (e) {
    gpuDisabled = true;
  }

  return gpuDisabled;
}

export async function isHeadlessBrowser() {
  if (isNode()) {
    return false; // disabled on server side
  }

  if (navigator.userAgent.toLowerCase().includes('headless')) {
    return true;
  }

  if (navigator.webdriver) {
    return true;
  }

  if (!navigator.plugins || (navigator.plugins && navigator.plugins.length === 0)) {
    return true;
  }

  const permissionDenied =
    navigator.permissions &&
    (await navigator.permissions.query({ name: 'notifications' }).then(permissionStatus => {
      if (Notification.permission === 'denied' && permissionStatus.state === 'prompt') {
        return true;
      }
      return false;
    }));

  if (permissionDenied) {
    return true;
  }

  return isGpuDisabled();
}
