import { useRef, useState, useCallback } from 'react';

/**
 * Returns value and function for toggle value between true/false.
 * @param {boolean} [initialValue] - initial value (true/false).
 * @param {number} [limit] - limit number of toggles.
 * @return {[boolean, function]}
 */
export const useToggle = (initialValue = false, { limit = null } = {}) => {
  const [value, setValue] = useState(initialValue);
  const toggleLimitCounterRef = useRef(0);

  const toggle = useCallback(() => {
    const hasToggleLimit = typeof limit === 'number';

    if (hasToggleLimit) {
      if (limit > toggleLimitCounterRef.current) {
        toggleLimitCounterRef.current += 1;
        setValue(v => !v);
      }
    } else {
      setValue(v => !v);
    }
  }, [limit]);

  return [value, toggle];
};
