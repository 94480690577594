import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { get } from 'lodash';
import { RenderWhenViewable } from 'site-modules/shared/components/render-when-viewable/render-when-viewable';
import { getPwtEnabled } from 'client/utils/pwt/get-pwt-enabled-page';

import { NativeAdDefaultProps, NativeAdPropTypes, VERTICAL_ALIGN } from './ad-unit-prop-types';
import { AdUnit } from './ad-unit';
import { AdUnitAsync } from './ad-unit-async';

const NATIVE_FED_TEST = 'nativefedtest';

const AD_CONTAINER_HEIGHTS = {
  mrect: '282px',
  mmrect: '312px',
  mediumrectangle: '268px',
  mspot: '122px',
  spot: '122px',
  sponsoredvideo: '363px',
  msponsoredvideo: '363px',
  enhancedspot: '198px',
  vdp: '144px',
  filmstripincentive: '174px',
  'vdp,filmstripincentive': '174',
  filmstripincentivecore: '104px',
  filmstrip: '358px',
  edhighimpact: '288px',
  medhighimpact: '221px',
  billboard: '250px',
};

function NativeAdUI(props) {
  const {
    wrapperClass,
    adNameWired,
    adNameMobile,
    wiredBreakpoints,
    mobileBreakpoints,
    wiredOnly,
    mobileOnly,
    isMobile,
    nativeFedTestFlag,
    position,
    nativeStyle,
    lazyLoad,
    useMinHeight,
    altMinHeight,
    renderWhenViewable,
    verticalOffset,
    customSizes,
    customSizesWired,
    customSizesMobile,
    addCustomSizes,
    addCustomSizesWired,
    addCustomSizesMobile,
    verticalAlign,
    style,
    pwtEnabled,
    isEditorialPageWithInlineMobileAd,
    renderOnScroll,
    ...restProps
  } = props;

  const name = isMobile ? adNameMobile : adNameWired;
  const breakpoints = isMobile ? mobileBreakpoints : wiredBreakpoints;
  const showOnCurrentLayout = (isMobile && !wiredOnly) || (!isMobile && !mobileOnly);
  const renderedAdType = nativeStyle !== 'none' ? nativeStyle : name;
  const customSizesByBreakpoint = isMobile ? customSizesMobile : customSizesWired;
  const addCustomSizesByBreakpoint = isMobile ? addCustomSizesMobile : addCustomSizesWired;

  const containerStyles = {
    minHeight: altMinHeight || AD_CONTAINER_HEIGHTS[renderedAdType],
    overflow: 'visible',
  };

  const adName = nativeFedTestFlag ? NATIVE_FED_TEST : name;

  const adUnitProps = {
    adName,
    nativeStyle,
    position,
    ...breakpoints,
    ...restProps,
    renderOnScroll: pwtEnabled ? false : renderOnScroll,
    renderWhenViewable: pwtEnabled ? false : renderWhenViewable,
    customSizes: customSizesByBreakpoint || customSizes,
    addCustomSizes: addCustomSizesByBreakpoint || addCustomSizes,
  };

  const adUnit = <AdUnit {...adUnitProps} />;

  const isInlineMobileMrect =
    isEditorialPageWithInlineMobileAd && isMobile && ['mmrect', 'mediumrectangle'].includes(renderedAdType);

  return (
    showOnCurrentLayout && (
      <div
        className={classnames('native-ad-wrapper', wrapperClass, {
          'd-flex justify-content-center': !!verticalAlign && verticalAlign !== VERTICAL_ALIGN.TOP,
          'align-items-center': verticalAlign === VERTICAL_ALIGN.CENTER,
          'align-items-end': verticalAlign === VERTICAL_ALIGN.BOTTOM,
        })}
        data-viewability={`${nativeStyle}-${position}`}
        style={{ ...style, ...(useMinHeight ? containerStyles : null) }}
      >
        {lazyLoad && <AdUnitAsync {...adUnitProps} />}
        {!lazyLoad && (pwtEnabled || !renderWhenViewable) && adUnit}
        {!lazyLoad && renderWhenViewable && !pwtEnabled && (
          <Fragment>
            {!isInlineMobileMrect && <RenderWhenViewable verticalOffset={verticalOffset}>{adUnit}</RenderWhenViewable>}
            {isInlineMobileMrect && (
              <RenderWhenViewable verticalOffset={`${parseInt(verticalOffset, 10) + 50}%`}>{adUnit}</RenderWhenViewable>
            )}
          </Fragment>
        )}
      </div>
    )
  );
}

NativeAdUI.propTypes = {
  ...NativeAdPropTypes,
  verticalOffset: PropTypes.string,
  style: PropTypes.shape({}),
  pwtEnabled: PropTypes.bool,
  isEditorialPageWithInlineMobileAd: PropTypes.bool,
};

NativeAdUI.defaultProps = {
  ...NativeAdDefaultProps,
  verticalOffset: '50%',
  style: {},
  pwtEnabled: false,
  isEditorialPageWithInlineMobileAd: false,
};

export const mapStateToProps = state => ({
  isMobile: state.mobile,
  nativeFedTestFlag: state.featureFlags.nativeFedTest,
  pwtEnabled: getPwtEnabled(state),
  isEditorialPageWithInlineMobileAd: get(state.pageContext.page, 'options.isEditorialPageWithInlineMobileAd', false),
});
export const NativeAd = connect(mapStateToProps)(NativeAdUI);
