import { DEFAULT_LOCATION } from 'client/constants/location';

export const REFRESH_ZONES = {
  ADHESION: 'adhesion',
};

export const WEBSITE_CLICKOUT_CUSTOM = 'website_clickout_custom';
export const DEALER_CLICKOUT_CUSTOM = 'dealer_clickout_custom';

/**
 * Return true if location is not default.
 *
 * @param {Object} location
 * @returns {boolean}
 */
export function isAdEnabledByZip(location) {
  return location && location.zipCode && location.zipCode !== DEFAULT_LOCATION.zipCode;
}

export function isAdEnabledByDma(location) {
  return location && location.ipDma && location.ipDma !== DEFAULT_LOCATION.dma;
}

/**
 * DFP targeting whitelist.
 *
 * @key {string}- name of the targeting, i.e. 'breakpoint', 'amp', 'dco', etc.
 * @value {array} - name of the nativeStyle to be included in the targeting, i.e. 'photoflipper', 'mediumrectangle', 'vcp', etc.
 */
export const TARGETING_WHITELIST = {
  breakpoint: ['photoflipper', 'vdp', 'site-incentives'],
  dco: ['srp'],
};

/**
 * Return true if nativeStyle is in the whitelist.
 *
 * @param {Object} - whitelist
 * @param {string} - nativeStyle
 * @returns {boolean}
 */
export function includeTargeting(whitelist, nativeStyle) {
  return whitelist.includes(nativeStyle);
}
