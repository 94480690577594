export const PAGE_URL = {
  HOME: '/',
  NEW_CARS: '/new-cars/',
  CAR_INCENTIVES: '/car-incentives/',
  RESEARCH: '/research/',
  CAR_NEWS: '/car-news/',
  CALCULATORS: '/calculators/',
  DEALERSHIPS: '/dealerships/',
  ELECTRIC_CAR_INCENTIVES: '/electric-car/tax-credits-rebates-incentives/',
};
