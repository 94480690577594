export const INCENTIVES_AD = {
  AD_NAME: 'incentives-native-ad-v2',
  NATIVE_STYLE: 'site-incentives',
};

export const BUILD_PRICE_AD = {
  AD_NAME: 'build-and-price-native-ad-v2',
  NATIVE_STYLE: 'site-buildprice',
};

export const PHOTOFLIPPER_AD = {
  AD_NAME: 'incentives-native-ad-v2',
  NATIVE_STYLE: 'site-photoflipper',
};

export const PRICING_AD = {
  AD_NAME: 'pricing-native-ad-v2',
  NATIVE_STYLE: 'site-pricing',
};

export const SRP_AD = {
  AD_NAME: 'srp-native-ad-v2',
  NATIVE_STYLE: 'site-srp',
};

export const SEGMENT_LISTING_AD = {
  AD_NAME: 'segment-listing-native-ad-v2',
  NATIVE_STYLE: 'site-segmentlisting',
};

export const EAS_LISTING_AD = {
  AD_NAME: 'eas-srp-native-ad',
  NATIVE_STYLE: 'site-easlisting',
};

export const INSIGHT_AD = {
  AD_NAME: 'insight-native-ad-v2',
  NATIVE_STYLE: 'site-insight',
};
/**
 *  IMPORTANT: any new ad unit should be defined in these maps: SITE_SERVED_ADS, REQUIRED_PARAMS
 */
export const SITE_SERVED_ADS = [
  INCENTIVES_AD.AD_NAME,
  BUILD_PRICE_AD.AD_NAME,
  PHOTOFLIPPER_AD.AD_NAME,
  PRICING_AD.AD_NAME,
  SRP_AD.AD_NAME,
  SEGMENT_LISTING_AD.AD_NAME,
  EAS_LISTING_AD.AD_NAME,
];

/**
 *  Required parameters for adzerk service by ad unit type
 */
export const REQUIRED_PARAMS = {
  [INCENTIVES_AD.AD_NAME]: ['model'],
  [BUILD_PRICE_AD.AD_NAME]: ['model'],
  [PHOTOFLIPPER_AD.AD_NAME]: ['model'],
  [PRICING_AD.AD_NAME]: ['model'],
  [SRP_AD.AD_NAME]: ['model', 'bt'],
  [SEGMENT_LISTING_AD.AD_NAME]: ['type'],
  [EAS_LISTING_AD.AD_NAME]: ['model'],
  [INSIGHT_AD.AD_NAME]: ['model'],
};
