import {
  NO_VEHICLE_GRAY_IMAGE_SEDAN,
  NO_VEHICLE_GRAY_IMAGE_SUV,
  NO_VEHICLE_GRAY_IMAGE_TRUCK,
} from 'site-modules/shared/constants/images';
import { getStaticImageUrl } from 'client/utils/image-helpers';

export function getNoVehicleImage(bodyType) {
  switch (bodyType) {
    case 'SUV': {
      return NO_VEHICLE_GRAY_IMAGE_SUV;
    }
    case 'Pickup': {
      return NO_VEHICLE_GRAY_IMAGE_TRUCK;
    }
    default: {
      return NO_VEHICLE_GRAY_IMAGE_SEDAN;
    }
  }
}

export function getNoVehicleImageToDisplay(bodyType) {
  const noVehicleImage = getNoVehicleImage(bodyType);
  return getStaticImageUrl(noVehicleImage);
}
