import { createModelSegment } from 'client/data/luckdragon/segment';
import { Location } from 'client/constants/location';
import { EdmundsAPI } from 'client/data/api/api-client';
import { withMetrics } from 'client/data/api/api-metrics';

export const LocationEntities = {
  Location,
};

export const LocationModel = createModelSegment('location', [
  {
    path: 'zips["{zip}"]',
    resolve: ({ zip }, context) => withMetrics(EdmundsAPI, context).fetchJson(`/zip/v2/${zip}`),
  },
]);
