import { isNewState } from 'site-modules/shared/utils/publication-states';
import { INVENTORY_TYPES } from 'client/constants/inventory-types';

/**
 * Maps publication state to inventory type based on the following logic
 * - New publication states are mapped to 'NEW' inventory type
 * - Used publication states are mapped to 'USED,CPO' inventory types
 * - Used publication states are mapped only to 'USED' when `skipUsedTypeAdjusting` flag is presented
 * @param {String} publicationState
 * @param {boolean} [skipUsedTypeAdjusting]
 */
export function getInventoryTypeForPublicationState(publicationState, skipUsedTypeAdjusting) {
  if (!publicationState) {
    return '';
  }
  if (isNewState(publicationState)) {
    return INVENTORY_TYPES.NEW;
  }
  return skipUsedTypeAdjusting ? INVENTORY_TYPES.USED : `${INVENTORY_TYPES.USED},${INVENTORY_TYPES.CPO}`;
}
