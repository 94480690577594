import { get } from 'lodash';

/**
 * Removes all non-numeric characters
 * @param phone
 */
export function clearPhone(phone) {
  return phone.replace(/\D/g, '');
}

/**
 * Converts phone to nice formatted phone. E.g. 8886975365 => (888) 697-5365
 * @param phone
 * @returns {*}
 */
export function createNicePhone(phone) {
  const matchesNiceFormat = phone.match(/\(\d{3}\) \d{3}-\d{4}/);
  if (matchesNiceFormat) {
    return phone;
  }
  return clearPhone(phone).replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
}

/**
 * Converts string to phone number. E.g. 8886975365 => 888-697-5365
 * @param {string} phone
 * @returns {string}
 */
export function convertToPhone(phone) {
  if (typeof phone === 'string' && phone.length === 10) {
    const matchesNiceFormat = phone.match(/\d{3}-\d{3}-\d{4}/);
    if (matchesNiceFormat) {
      return phone;
    }
    return clearPhone(phone).replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  }
  return phone;
}

/**
 * Convert phone object to formatted phone number.
 * style = 'dash' { areaCode: '855', postfix: '3843', prefix: '860', } => 855-860-3843
 * default style { areaCode: '855', postfix: '3843', prefix: '860', } => (855) 860-3843
 * @param {object} phoneObj
 * @param {string} [style]
 * @returns {string}
 */
export function toDisplayString(phoneObj, style) {
  const areaCode = get(phoneObj, 'areaCode');
  const prefix = get(phoneObj, 'prefix');
  const postfix = get(phoneObj, 'postfix');

  if (!(areaCode && prefix && postfix)) {
    return '';
  }

  switch (style) {
    case 'dash':
      return `${areaCode}-${prefix}-${postfix}`;
    case 'dot':
      return `${areaCode}.${prefix}.${postfix}`;
    default:
      return createNicePhone(`${areaCode}${prefix}${postfix}`);
  }
}
