import { ClientConfig } from 'client/configuration';
import { isNode } from 'client/utils/environment';
import { setCookie } from 'client/utils/set-cookie';
import { logger } from 'client/utils/isomorphic-logger';

const EDM_IAS_DATA_COOKIE_NAME = 'edm-ias-data';
const EDM_IAS_DATA_TIMEOUT = 2000;

function setIASDataCookie(data) {
  const newCookieValue = JSON.stringify({ fr: data.fr });
  document.cookie = setCookie(EDM_IAS_DATA_COOKIE_NAME, newCookieValue, { path: '/' }, true);
}

function isJson(str) {
  try {
    JSON.parse(str);
  } catch (error) {
    return false;
  }
  return true;
}

export function getStoredIasData(visitor) {
  return visitor.iasData && isJson(visitor.iasData) ? JSON.parse(visitor.iasData) : null;
}

/**
 * requires modern Grade A browser that supports AbortController
 */
export async function fetchWithTimeout(url) {
  if (typeof AbortController === 'undefined') return {};

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), EDM_IAS_DATA_TIMEOUT);

  const response = await fetch(url, {
    ...EDM_IAS_DATA_TIMEOUT,
    signal: controller.signal,
  });

  clearTimeout(id);

  return response.json();
}

/**
 * makes a request to IAS pub service that returns user data
 * purpose of this function is to retrieve IAS fr flag to be used in ad targeting
 */
export async function getIasData(visitor) {
  if (isNode()) {
    return null; // disabled on server side
  }

  if (!visitor) {
    return null;
  }

  if (!visitor.session) {
    return null;
  }

  const iasData = getStoredIasData(visitor);
  if (iasData !== null) {
    return iasData;
  }

  const windowWidth = window.innerWidth;
  const screenWidth = window.screen.width;
  const windowUrl = encodeURI(window.location.href);
  const url = ClientConfig.get('iasApiUrl')
    .replace('{windowWidth}', windowWidth)
    .replace('{screenWidth}', screenWidth)
    .replace('{windowUrl}', windowUrl)
    .replace('{visitorSession}', visitor.session);
  try {
    const responseData = await fetchWithTimeout(url);
    if (responseData.fr) {
      setIASDataCookie(responseData);
      return responseData;
    }
  } catch (error) {
    logger('error', error, 'Error while fetching getIasData');
  }
  return null;
}

export function getIvtClassName(visitor = { iasData: null }, isEnableIAS) {
  let isIvt = 'false';
  if (visitor.iasData !== null) {
    const iasData = isEnableIAS && getStoredIasData(visitor);
    isIvt = iasData && iasData.fr ? iasData.fr : null;
  }
  let ivtClassName;
  if (isIvt === 'true') {
    ivtClassName = 'invisible is-ivt';
  }
  return ivtClassName;
}
