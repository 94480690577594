import { get } from 'lodash';
import { MAKE, MODEL } from 'site-modules/shared/constants/allowed-inventory-request-params';

export function getMakeName({ facets, make }) {
  const makeFacet = facets.find(({ type }) => type === MAKE) || {};
  const makeFacetValues = get(makeFacet, 'values', []);
  const selectedMake = makeFacetValues.find(({ value }) => value === make) || {};
  return selectedMake.name;
}
export function getModelName({ facets, makeName, model }) {
  const modelFacet = facets.find(({ type }) => type === MODEL) || {};
  const modelFacetValues = get(modelFacet, `groups[${makeName}]`, []);
  const selectedModel = modelFacetValues.find(({ value }) => value === model) || {};
  return selectedModel.name || '';
}

export function getModelFamilyName({ facets, makeName, model }) {
  const modelFacet = facets.find(({ type }) => type === MODEL) || {};
  const modelFacetValues = modelFacet?.groups?.[makeName];
  const selectedModel = modelFacetValues?.find(({ value }) => value === model) || {};
  return selectedModel.modelFamilyName || '';
}

export function getMultiMMCriticalKeyValue({ makesNum, modelsNum }) {
  // only select filters
  let multiMM = 'select';

  if (makesNum >= 2 || modelsNum >= 2) {
    // with bubbles
    multiMM = 'bubbles';
  } else if (makesNum === 1) {
    // with 'add another' link
    multiMM = 'addAnother';
  }

  return multiMM;
}
