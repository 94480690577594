import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { PAGE_EVENTS } from 'client/constants/page-events';
import { PHOTOFLIPPER_EVENTS } from 'site-modules/shared/constants/custom-events';
import { fireDefaultEvent } from 'client/engagement-handlers/ads-engagement-handler/ads-engagement-handler';
import { EventToolbox } from 'client/utils/event-toolbox';
import { getPwtEnabled } from 'client/utils/pwt/get-pwt-enabled-page';

import { getCreativeConfigAdPathByVehicle } from 'site-modules/shared/components/native-ad/utils/utils';
import { RenderWhenViewable } from 'site-modules/shared/components/render-when-viewable/render-when-viewable';

import { AdUnitPropTypes, AdUnitDefaultProps } from './ad-unit-prop-types';
import { getClassNames, destroyAdDependedOnCurrentVehicle } from './ad-unit-helper';
import { AdUnitSlot } from './ad-unit-slot';

import './ad-unit.scss';

const empty = {};

let totalAdUnitsToBeRendered = 0;

export function getTotalAdUnitsToBeRendered() {
  return totalAdUnitsToBeRendered;
}

export class AdUnitUI extends Component {
  constructor(props) {
    super(props);

    const { dependedOnCurrentVehicle, currentVehicle, siteServedAdsUpdater, customTargeting } = props;

    this.state = {
      // the renderOnScroll property defers the dfp call until user interaction via scroll
      // absence of this property renders the ad in accordance to renderWhenViewable
      doRender: this.props.renderOnScroll ? 0 : 1,
      destroyed: false,
      isRenderPlaceholderComponent: false,
      shouldBeDestroyedToRecreate: false,
      adVehiclePath:
        dependedOnCurrentVehicle && currentVehicle && getCreativeConfigAdPathByVehicle(false, currentVehicle),
      siteServedAdsUpdater,
      dependedOnCurrentVehicle,
      navigationBetweenPages: get(customTargeting, 'navigationBetweenPages', 0),
      adZone: null,
      hasAdZone: null,
      isSSR: true,
    };

    this.renderDeferred = this.renderDeferred.bind(this);
    this.ref = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.breakpoint && !nextProps.renderOnScroll) {
      return {
        doRender: prevState.doRender + 1,
        ...destroyAdDependedOnCurrentVehicle(nextProps, prevState),
      };
    }
    return destroyAdDependedOnCurrentVehicle(nextProps, prevState);
  }

  componentDidMount() {
    // Hydration failed fix
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ isSSR: false });

    if (this.props.renderOnScroll) {
      document.addEventListener('scroll', this.renderDeferred);
    }
    EventToolbox.on(PHOTOFLIPPER_EVENTS.TOGGLE, this.createOrDestroySlotOnModalOpen);
    // For tracking purposes fire event again after page update without component unmount
    EventToolbox.on(PAGE_EVENTS.PAGE_UPDATE, fireDefaultEvent);

    if (this.adSlotDidOrWillRender) {
      totalAdUnitsToBeRendered += 1;
    }

    this.checkAdZone();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const prevShouldRender = this.breakpointDefinedAndMatchesThisUnit(this.props);
    const nextShouldRender = this.breakpointDefinedAndMatchesThisUnit(nextProps);

    if (!prevShouldRender && !nextShouldRender) {
      return false;
    }

    const { refreshPageNavigationDisabled, location } = nextProps;
    if (this.adSlotWasRendered && refreshPageNavigationDisabled && !!location?.query?.pagenumber) {
      return false;
    }

    return (
      nextState.doRender !== this.state.doRender ||
      nextState.destroyed !== this.state.destroyed ||
      this.state.hasAdZone === null
    );
  }

  componentDidUpdate() {
    if (this.state.shouldBeDestroyedToRecreate) {
      // Ad units re-creates for the pages where vehicle can be changed, e.g. SRP
      // Site-served ad units when click correlator should be changed
      this.createAdDependedOnCurrentVehicle();
    }
    this.checkAdZone();
  }

  componentWillUnmount() {
    // attempt to remove the scroll listener in the event a scroll never occurred
    document.removeEventListener('scroll', this.renderDeferred);

    EventToolbox.off(PHOTOFLIPPER_EVENTS.TOGGLE, this.createOrDestroySlotOnModalOpen);
    EventToolbox.off(PAGE_EVENTS.PAGE_UPDATE, fireDefaultEvent);
    if (this.adSlotDidOrWillRender) {
      // we have had a bug where on page load a certain ad would render, unmount, rerender
      // without this check a bug like that would prevent initializqation of all ads
      totalAdUnitsToBeRendered -= 1;
    }
  }

  breakpointDefinedAndMatchesThisUnit = props => {
    if (!props.breakpoint) {
      // breakpoint is not yet initialized
      return false;
    }
    return props.all ? true : props[props.breakpoint];
  };

  createOrDestroySlotOnModalOpen = event => {
    const { ads, isModalOpen } = event.detail;
    const { adName, position } = this.props;
    if (ads && ads[adName] && ads[adName].includes(position)) {
      this.setState({
        destroyed: isModalOpen,
      });
    }
  };

  createAdDependedOnCurrentVehicle = () => {
    this.setState({ shouldBeDestroyedToRecreate: false });
  };

  checkAdZone = () => {
    const { hasAdZone } = this.state;

    if (hasAdZone === null && this.ref.current) {
      const adZoneElement = this.ref.current.closest('[data-ad-zone]');
      this.setState({
        adZone: adZoneElement && adZoneElement.dataset.adZone,
        hasAdZone: !!(adZoneElement && adZoneElement.dataset.adZone),
      });
    }
  };

  renderDeferred() {
    document.removeEventListener('scroll', this.renderDeferred);
    this.setState(prevState => ({
      doRender: prevState.doRender + 1,
    }));
  }

  render() {
    const {
      adName,
      nativeStyle,
      pageVehicle,
      currentVehicle,
      hasVehicleContext,
      siteServedAdsUpdater,
      adCurrentZone,
      adZones,
      isIncrementPosition,
      rerenderWhenViewable,
      verticalOffset,
      ...restProps
    } = this.props;

    const { adZone, hasAdZone, shouldBeDestroyedToRecreate, destroyed, doRender, isSSR } = this.state;

    // ads is disabled
    if (!this.props.enabled) {
      // ff is disabled
      return false;
    }

    const containerProps = {
      className: getClassNames(this.props, this.state),
      'data-adname': adName,
      'data-native-style': nativeStyle,
      ref: this.ref,
    };
    const emptyContainer = <div {...containerProps} />;

    if (hasVehicleContext && isEmpty(pageVehicle) && isEmpty(currentVehicle)) {
      return emptyContainer;
    }

    if (shouldBeDestroyedToRecreate) {
      return emptyContainer;
    }

    // site-served companion, type or model should be defined
    if (
      siteServedAdsUpdater &&
      !currentVehicle &&
      !get(pageVehicle, 'type.niceName') &&
      !get(pageVehicle, 'model.niceName')
    ) {
      return emptyContainer;
    }

    this.adSlotDidOrWillRender = false;

    if (destroyed) {
      return emptyContainer;
    }

    const shouldRenderAdSlot = this.breakpointDefinedAndMatchesThisUnit(this.props) && doRender > 0;
    if (!shouldRenderAdSlot) {
      return emptyContainer;
    }

    this.adSlotDidOrWillRender = true;

    if (isSSR) {
      return emptyContainer;
    }

    // destroy if ad unit does not belong to the zone
    if (hasAdZone && !isIncrementPosition && adCurrentZone && adZone !== adCurrentZone) {
      return emptyContainer;
    }

    // waiting for the zone to be active
    if (hasAdZone && isIncrementPosition && !adZones.includes(adZone)) {
      return emptyContainer;
    }

    this.adSlotWasRendered = true;

    const renderAdUnitSlot = (
      <AdUnitSlot
        {...restProps}
        adName={adName}
        nativeStyle={nativeStyle}
        pageVehicle={pageVehicle}
        currentVehicle={currentVehicle}
        isIncrementPosition={isIncrementPosition}
        hasAdZone={hasAdZone}
      />
    );

    return rerenderWhenViewable ? (
      <RenderWhenViewable verticalOffset={verticalOffset}>{renderAdUnitSlot}</RenderWhenViewable>
    ) : (
      renderAdUnitSlot
    );
  }
}

AdUnitUI.propTypes = {
  ...AdUnitPropTypes,
  hasVehicleContext: PropTypes.bool,
  vehicleContextChange: PropTypes.bool,
  siteServedAdsUpdater: PropTypes.number,
  dependedOnCurrentVehicle: PropTypes.bool,
  prefixId: PropTypes.string,
  pwtEnabled: PropTypes.bool,
  adCurrentZone: PropTypes.string,
  adZones: PropTypes.arrayOf(PropTypes.string),
  isIncrementPosition: PropTypes.bool,
  rerenderWhenViewable: PropTypes.bool,
};

AdUnitUI.defaultProps = {
  ...AdUnitDefaultProps,
  hasVehicleContext: false,
  vehicleContextChange: false,
  siteServedAdsUpdater: 0,
  dependedOnCurrentVehicle: false,
  prefixId: undefined,
  pwtEnabled: false,
  adCurrentZone: null,
  adZones: [],
  isIncrementPosition: false,
  rerenderWhenViewable: false,
};

/**
 * Maps store state pageContext to component properties
 *
 * @param  {Object} state Store state
 * @return {Object}       State to props mapping
 */
export const mapStateToProps = state => ({
  enabled: state.featureFlags.ads,
  page: state.pageContext.page,
  location: state.pageContext.location,
  ads: state.pageContext.ads || empty,
  pageVehicle: state.pageContext.vehicle || empty,
  mobile: state.mobile,
  visitor: state.visitor,
  breakpoint: state.pageContext.breakpointDeprecated2,
  hasVehicleContext: get(state.pageContext.page, 'options.hasVehicleContext', false),
  vehicleContextChange: get(state.pageContext.page, 'options.vehicleContextChange', false),
  adCurrentZone: get(state.pageContext, 'ads.adCurrentZone'),
  adZones: get(state.pageContext, 'ads.adZones'),
  pwtEnabled: getPwtEnabled(state),
});

export const AdUnit = connect(mapStateToProps)(AdUnitUI);
