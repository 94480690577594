import { TYPE_ELECTRIC_CAR_LINK_SLUG } from 'site-modules/shared/utils/upper-funnel/type-mapping';
import { CAR_TYPES } from 'site-modules/shared/constants/upper-funnel/car-types';

/**
 * Generate link to type page from vehicle type slug
 *
 * @param slug {string}
 * @returns {string}
 */
export function getTypeUrl(slug) {
  const correctTypeSlug = slug === CAR_TYPES.ELECTRIC ? TYPE_ELECTRIC_CAR_LINK_SLUG : slug;
  return `/${correctTypeSlug}/`;
}
