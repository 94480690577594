import { SORT_BY, PAYMENT_TYPE, LEASE_PAYMENT } from 'site-modules/shared/constants/allowed-inventory-request-params';
import { isLeaseSRP, isNationalSRP } from 'client/site-modules/shared/utils/inventory/srp-type-checkers';

export const CBP_SORT_BY = 'boostControl5LeaseDeals:desc';
export const LEASE_PAYMENT_TYPE = 'lease';
export const LEASE_PAYMENT_SORT = 'leasePayment';

export function isPaymentTypeLease(selectedFacets) {
  return (
    selectedFacets &&
    (!!(selectedFacets[PAYMENT_TYPE] && selectedFacets[PAYMENT_TYPE][0] === LEASE_PAYMENT_TYPE) ||
      !!selectedFacets[LEASE_PAYMENT])
  );
}

export function getCBPSortSRPFilter({ filters, urlContext, skipCBPButton }) {
  const isPaymentTypeLeaseSRP = isPaymentTypeLease(filters);
  if (filters[SORT_BY] || skipCBPButton) return filters;

  if ((isPaymentTypeLeaseSRP || isLeaseSRP(urlContext)) && !isNationalSRP(urlContext)) {
    return {
      ...filters,
      [SORT_BY]: [CBP_SORT_BY],
    };
  }

  return filters;
}
