import { getPlural } from 'client/utils/plural';
import { addZipCode } from 'site-modules/shared/components/native-ad/utils/utils';
import { getFormattedIncentives } from 'site-modules/shared/utils/incentives';
import { get } from 'lodash';

export function getFilteredIncentives(incentives) {
  const financingIncentives = getFormattedIncentives(get(incentives, 'financing', [])).displayIncentives.filter(
    inc => inc.type === 'LOW_APR'
  );
  const customerCashIncentives = getFormattedIncentives(get(incentives, 'bonus', [])).displayIncentives.filter(
    inc => inc.contentType === 'CUSTOMER_CASH'
  );
  return financingIncentives.concat(customerCashIncentives);
}

/**
 *  AC1 Headline is blank + offers are available -> “[x] Offers Near [zip]”
 *  AC2 Headline is blank + no offers available -> “See Offers Near [zip]”
 *  AC3 Headline is not blank -> example values “Find Deals Near %%PATTERN:zip%%” or “Shop Now”
 * @param headline
 * @param offersNumber
 * @param zipCode
 * @param ipZipCode
 * @returns {string}
 */
export function getDisplayHeadline(
  headline,
  offersNumber,
  zipCode,
  ipZipCode,
  defaultHeadlineText,
  reduceOffers,
  reduceOffersToValue,
  location
) {
  const locationValue = location ? `${location.city}, ${location.stateCode}` : zipCode;

  if (headline) {
    return addZipCode(headline, locationValue);
  }
  const locationText = ipZipCode === zipCode ? `Near ${locationValue}` : 'Nearby';
  if (!offersNumber) {
    return addZipCode(defaultHeadlineText, locationText);
  }
  const displayOffersNumber = reduceOffers && offersNumber > reduceOffers ? reduceOffersToValue : offersNumber;
  return `${displayOffersNumber} ${getPlural('Offer', displayOffersNumber)} ${locationText}`;
}

export function getTrackingValue(
  headline,
  offersNumber,
  zipCode,
  ipZipCode,
  defaultHeadlineText,
  reduceOffers,
  reduceOffersToValue
) {
  if (headline) {
    return headline;
  }

  const locationText = ipZipCode === zipCode ? 'Near' : 'Nearby';
  if (!offersNumber) {
    return addZipCode(defaultHeadlineText, locationText).replace(/\s/g, '-');
  }
  const trackOffersNumber = reduceOffers && offersNumber > reduceOffers ? reduceOffersToValue : offersNumber;

  return `${trackOffersNumber}-${getPlural('Offer', trackOffersNumber)}-${locationText}`;
}
