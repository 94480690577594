import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { logger } from 'client/utils/isomorphic-logger';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';

const CREATIVE_ID = 'edm-minor-breakdown-display';

export class ErrorBoundaryComponent extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    message: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    pageName: PropTypes.string.isRequired,
  };

  static defaultProps = {
    children: null,
    message: null,
  };

  state = {
    hasError: false,
  };

  componentDidCatch(error, info) {
    const { pageName } = this.props;
    this.setState({
      hasError: true,
      error,
      info,
    });
    logger('error', error, info);
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_name: TrackingConstant.ACTION_SHOW_CONTENT,
        subaction_name: TrackingConstant.MINOR_BREAKDOWN_ERROR,
        action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
        action_cause: TrackingConstant.PAGE_LOAD_CAUSE,
        creative_id: CREATIVE_ID,
        value: error?.message ?? '',
        url: window?.location?.href ?? '',
        page_name: pageName,
      },
    });
    if (window?.newrelic) {
      window.newrelic.noticeError(error, { react_error_boundary: 'true' });
    }
  }

  render() {
    const { hasError, error, info } = this.state;
    const ErrorMessage = this.props.message;

    if (!hasError) return this.props.children;
    if (!ErrorMessage) return null;

    return <ErrorMessage error={error} info={info} />;
  }
}

const mapStateToProps = state => ({
  pageName: state?.pageContext?.page?.name ?? '',
});

export const ErrorBoundary = connect(mapStateToProps)(ErrorBoundaryComponent);
