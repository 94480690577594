/**
 * to allow people to write
 * import { Experiment, Recipe } from 'site-modules/shared/components/experiment';
 *
 * instead of
 * import { Experiment } from 'site-modules/shared/components/experiment/experiment';
 * import { Recipe } from 'site-modules/shared/components/experiment/recipe';
 *
 */
import { Experiment as E } from './experiment';
import { Recipe as R } from './recipe';
import { MultivariateExperiment as MultivariateExperimentComponent } from './multivariate-experiment';

export const Experiment = E;
export const Recipe = R;

/**
 * Exports Experiment component to be used to define a Ax Experiment.
 */
export const MultivariateExperiment = MultivariateExperimentComponent;

// please do not add any other exports here without review from PLAT
