import { get, isEmpty } from 'lodash';
import classnames from 'classnames';
import { DFPManager } from '@edmunds/react-dfp';
import { pwtAdRefresher } from 'client/utils/pwt/ad-refresher';
import { getVehicleContext } from 'site-modules/shared/components/ads-context/context/page-context';
import { getCreativeConfigAdPathByVehicle } from 'site-modules/shared/components/native-ad/utils/utils';
import { TARGETING_WHITELIST, includeTargeting } from 'site-modules/shared/constants/ads';
import { getCorrelatorTs } from 'client/engagement-handlers/helper';
import { generateSitename } from './context/sitename';
import { getSizes, getNewSizes } from './context/sizes';

/**
 * Get Ad Unit Sizes
 *
 *  --- customSizes - override existing sizes
 *  --- addCustomSizes - add to existing sizes
 *
 * @param  {Object} props
 * @param  {Boolean} enableFluidSizeForNativeAd - enable or not 'fluid' and '1x1' sizes for Native Ad
 * @return {Array} Ad Unit sizes
 */
export const getAdSizes = (props, enableFluidSizeForNativeAd = false) => {
  const { adName, customSizes, addCustomSizes } = props;

  if (isEmpty(customSizes)) {
    const sizes = enableFluidSizeForNativeAd ? getNewSizes(adName) : getSizes(adName);
    if (!isEmpty(addCustomSizes)) {
      return [...sizes, ...addCustomSizes];
    }
    return sizes;
  }
  return customSizes;
};

const getClickCorrelatorTs = adName =>
  ['companion', 'mcompanion'].includes(adName) ? { clickcorrelatorts: getCorrelatorTs() } : {};

/**
 * Get targeting params for Ad Unit
 *
 * @param  {Object} props
 * @return {Object} targeting
 */
export const getTargeting = props => {
  const { adName, position, customTargeting, nativeStyle, dco, currentVehicle, page, visitor, breakpoint } = props;
  const usprivacyStr = get(visitor, 'privacy.uspString', '');

  let targeting = {
    adname: adName,
    pos: position,
    ...getClickCorrelatorTs(adName),
    ...customTargeting,
  };

  if (!isEmpty(nativeStyle)) {
    targeting.nativestyle = nativeStyle;
  }

  if (dco && includeTargeting(TARGETING_WHITELIST.dco, nativeStyle)) {
    targeting.dco = dco;
  }

  if (breakpoint && includeTargeting(TARGETING_WHITELIST.breakpoint, nativeStyle)) {
    targeting.breakpoint = breakpoint;
  }

  if (currentVehicle) {
    // some parameters can be overridden with custom targeting
    targeting = {
      ...getVehicleContext(currentVehicle, get(page, 'options', {})),
      ...targeting,
    };
  }

  if (usprivacyStr) {
    targeting.us_privacy = usprivacyStr;
  }
  return targeting;
};

/**
 * Get Ad Unit Path
 *
 * @param  {Object} props
 * @return {String} ad unit path
 */
export const getSitename = props => {
  const { page, location, currentVehicle, pageVehicle, mobile, breakpoint } = props;

  return generateSitename(page, location, currentVehicle || pageVehicle, mobile, breakpoint);
};

/**
 * Get class names for Ad Unit depending on setting props
 *
 * @param  {Object} props
 * @return {String} class names
 */
export const getClassNames = (props, state = {}) => {
  const { oneByOne, renderWhenViewable, showLabel, dfpCollapse } = props;
  const { isRenderPlaceholderComponent, isShowLabel } = state;

  return classnames('ad-unit', {
    'hidden-xs-up': oneByOne && !renderWhenViewable,
    'show-label': isShowLabel && showLabel && !oneByOne,
    'dfp-collapse': dfpCollapse || showLabel,
    'd-none': isRenderPlaceholderComponent,
  });
};

const PWT_AD_WHITELIST = ['mrect', 'leaderc', 'spot', 'mmrect', 'mab', 'mspot'];
export const isPwtWhitelistAd = adName => PWT_AD_WHITELIST.includes(adName);

export function refreshAdUnit(adName, slotId, skipPwtRefresh) {
  setTimeout(() => {
    if (isPwtWhitelistAd(adName)) {
      pwtAdRefresher([slotId]);
    } else if (!skipPwtRefresh) {
      // companion, mcompanion, filmstrip, eas, etc
      DFPManager.refresh(slotId);
    }
  }, 100);
}

export function destroyAdDependedOnCurrentVehicle(nextProps, prevState) {
  const {
    vehicleContextChange,
    dependedOnCurrentVehicle,
    currentVehicle,
    siteServedAdsUpdater,
    customTargeting,
  } = nextProps;

  if (!siteServedAdsUpdater) {
    return null;
  }

  // see props in client/data/models/native-ads-creative-config.js
  // path adTargeting.model
  if (dependedOnCurrentVehicle && currentVehicle) {
    const adVehiclePath = getCreativeConfigAdPathByVehicle(false, currentVehicle);
    const adVehiclePathPrev = prevState.adVehiclePath;
    const navigationBetweenPages = get(customTargeting, 'navigationBetweenPages', 0);
    if (
      adVehiclePath !== adVehiclePathPrev ||
      siteServedAdsUpdater !== prevState.siteServedAdsUpdater ||
      navigationBetweenPages !== prevState.navigationBetweenPages
    ) {
      return {
        shouldBeDestroyedToRecreate: true,
        adVehiclePath,
        siteServedAdsUpdater,
        dependedOnCurrentVehicle,
        navigationBetweenPages,
      };
    }
  } else if (dependedOnCurrentVehicle !== prevState.dependedOnCurrentVehicle) {
    return {
      shouldBeDestroyedToRecreate: true,
      dependedOnCurrentVehicle,
    };
  } else if (!vehicleContextChange && siteServedAdsUpdater !== prevState.siteServedAdsUpdater) {
    // The refresh action for site-served ads when click correlator should be changed
    return {
      shouldBeDestroyedToRecreate: true,
      siteServedAdsUpdater,
    };
  }

  return null;
}
