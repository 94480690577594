import React from 'react';
import { Link as RouterLink } from 'react-router-dom'; // eslint-disable-line no-restricted-imports

const absoluteUrlRegExp = /^https?:\/\/|^\/\//i;

export function Link(props) {
  if (absoluteUrlRegExp.test(props.to) || props.to === '#') {
    const { to, ...restProps } = props;
    return (
      <a href={to} {...restProps}>
        {props.children}
      </a>
    );
  }

  return React.createElement(RouterLink, props);
}
