export const CORE_SRP_MMYS = 'make/model/year/submodel';
export const CORE_SRP_URL_PATTERN = 'make/model/year';
export const MAKE_TYPE_SRP_URL_PATTERN = 'make/type';
export const USED_ALL = 'used-all';
export const NEW_MAKE_MODEL_URL_PATTERN = 'new-make-model-for-sale';
export const CARS_FOR_SALE_BY_OWNER = 'cars-for-sale-by-owner';
export const USED_CARS_STATE = 'used-cars-state';
export const MAKE_DEALERSHIP = 'make-dealership';
export const MAKE_DEALERSHIP_CITY_STATE = 'make-dealership-city-state';
export const MAKE_MODEL_LEASE_DEALS_STATE = 'make-model-lease-deals-state';
export const MAKE_MODEL_LEASE_DEALS_CITY_STATE = 'make-model-lease-deals-city-state';
export const SRP_FOR_SALE = 'make/model/year/for-sale';
