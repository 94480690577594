const cookiesForSameSiteNone = new Set(['visitor-id', 'session-id', 'edw', 'edmunds', 'edm-ias-data']);

/**
 * Serialize data into a cookie header.
 *
 * Serialize the a name value pair into a cookie string.
 * An optional options object specified cookie parameters.
 *
 * serialize('foo', 'bar', { httpOnly: true })
 *   => "foo=bar; httpOnly"
 *
 * @param {string} name
 * @param {string} val
 * @param {object} [options]
 * @param {boolean} isSameSiteNoneCompatible
 *
 * @return {string}
 * @public
 *
 * Used by express' res.cookie under the hood: https://github.com/jshttp/cookie/blob/master/index.js#L101
 */

export function setCookie(name, val, options, isSameSiteNoneCompatible = false) {
  const opt = options || {};
  const encode = encodeURIComponent;
  const enc = opt.encode || encode;
  const value = enc(val);

  /* eslint-disable-next-line no-control-regex */
  const fieldContentRegExp = /^[\u0009\u0020-\u007e\u0080-\u00ff]+$/;

  if (!fieldContentRegExp.test(name)) {
    throw new TypeError('argument name is invalid');
  }

  if (value && !fieldContentRegExp.test(value)) {
    throw new TypeError('argument val is invalid');
  }

  let str = `${name}=${value}`;

  if (opt.maxAge != null) {
    const maxAge = opt.maxAge - 0;
    if (isNaN(maxAge)) throw new Error('maxAge should be a Number');
    str += `; Max-Age=${Math.floor(maxAge)}`;
  }

  if (opt.domain) {
    if (!fieldContentRegExp.test(opt.domain)) {
      throw new TypeError('option domain is invalid');
    }

    str += `; Domain=${opt.domain}`;
  }

  if (opt.path) {
    if (!fieldContentRegExp.test(opt.path)) {
      throw new TypeError('option path is invalid');
    }

    str += `; Path=${opt.path}`;
  }

  if (opt.expires) {
    if (typeof opt.expires.toUTCString !== 'function') {
      throw new TypeError('option expires is invalid');
    }

    str += `; Expires=${opt.expires.toUTCString()}`;
  }

  if (opt.httpOnly) {
    str += '; HttpOnly';
  }

  if (cookiesForSameSiteNone.has(name)) {
    if (isSameSiteNoneCompatible) {
      str += '; Secure';
      str += '; SameSite=None';
    } else if (opt.secure) {
      str += '; Secure';
    }

    return str;
  }

  if (opt.secure) {
    str += '; Secure';
  }

  if (opt.sameSite) {
    const sameSite = typeof opt.sameSite === 'string' ? opt.sameSite.toLowerCase() : opt.sameSite;

    switch (sameSite) {
      case true:
        str += '; SameSite=Strict';
        break;
      case 'lax':
        str += '; SameSite=Lax';
        break;
      case 'strict':
        str += '; SameSite=Strict';
        break;
      case 'none':
        str += '; SameSite=None';
        break;
      default:
        throw new TypeError('option sameSite is invalid');
    }
  } else {
    str += '; SameSite=Lax';
  }

  return str;
}
