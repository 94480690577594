import { get } from 'lodash';
import { getModelState } from 'client/data/luckdragon/model';
import { VehicleModel } from 'client/data/models/vehicle';
import { resolveModel } from 'client/data/luckdragon/redux/model-actions';
import { parseVehicleForAction } from 'client/utils/parse-vehicle-for-action';
import {
  getPublicationStateByMultipleTypes,
  getPublicationStateForMakeModel,
} from 'site-modules/shared/utils/publication-states';
import { PUB_STATES } from 'client/constants/pub-states';
import { makeNiceName } from 'site-modules/shared/utils/nice-name';
import { UPDATE_VEHICLE } from './constants';

const TYPES = ['electric', 'suv', 'truck', 'hybrid', 'sedan', 'hatchback', 'coupe', 'minivan', 'convertible', 'wagon'];

/**
 * This function is specifically for the vehicle in the context and
 * should only be used for ads and tracking.
 * TODO: this function is not used anywhere
 */
export function buildContextVehicleFromMakeModel(makeModel) {
  return {
    make: {
      id: makeModel.make.id,
      name: makeModel.make.name,
      niceName: makeModel.make.slug,
    },
    model: {
      modelLinkCode: makeModel.modelLinkCode,
      name: makeModel.name,
      niceName: makeModel.slug,
    },
    modelYear: {
      year: 'agnostic',
    },
    publicationState: getPublicationStateForMakeModel(makeModel.pubStates),
  };
}

/**
 * This function is specifically for the vehicle in the context and
 * should only be used for ads and tracking.
 * urlContext vehicle is resolved by service https://qa-21-www.edmunds.com/gateway/api/url/v1/parse?url=/new-honda-accord-pricing
 */
export function buildContextVehicleFromUrlContext(urlContext, pubState) {
  return {
    make: {
      id: get(urlContext, 'vehicle.make.id', null),
      name: get(urlContext, 'vehicle.make.name', ''),
      niceName: get(urlContext, 'vehicle.make.slug', ''),
      adTargetId: get(urlContext, 'vehicle.make.adTargetId'),
    },
    model: {
      modelLinkCode: get(urlContext, 'vehicle.model.modelLinkCode', ''),
      name: get(urlContext, 'vehicle.model.name', ''),
      niceName: get(urlContext, 'vehicle.model.slug', ''),
      adTargetId: get(urlContext, 'vehicle.model.adTargetId'),
    },
    modelYear: {
      year: 'agnostic',
    },
    publicationState: pubState || PUB_STATES.NEW,
  };
}

/**
 * This function is specifically for the vehicle in the context and
 * should only be used for ads and tracking.
 *
 * @param {VehicleEntities.MakeModelSubmodelYear} makeModelSubmodelYear
 * @param {Boolean} isUsed
 * @param {Boolean} isPreProdVehicle
 */
export function buildContextVehicleFromMakeModelSubmodelYear(
  makeModelSubmodelYear,
  { isUsed = false, isPreProdVehicle = false } = {}
) {
  let publicationState;

  if (isUsed) {
    publicationState = 'used';
  } else if (isPreProdVehicle) {
    publicationState = 'preprod';
  } else {
    publicationState =
      makeModelSubmodelYear &&
      makeModelSubmodelYear.pubStates &&
      getPublicationStateForMakeModel(makeModelSubmodelYear.pubStates);
  }

  const edmundsTypeCategories = Object.keys(get(makeModelSubmodelYear, 'edmundsTypeCategories', {}));

  return !makeModelSubmodelYear
    ? {}
    : {
        make: {
          name: get(makeModelSubmodelYear, 'make.name', ''),
          niceName: get(makeModelSubmodelYear, 'make.slug', ''),
          id: get(makeModelSubmodelYear, 'make.id', ''),
          adTargetId: get(makeModelSubmodelYear, 'make.adTargetId'),
        },
        model: {
          name: get(makeModelSubmodelYear, 'model.name', ''),
          niceName: get(makeModelSubmodelYear, 'model.slug', ''),
          modelLinkCode: get(makeModelSubmodelYear, 'model.modelLinkCode', ''),
          adTargetId: get(makeModelSubmodelYear, 'model.adTargetId'),
        },
        modelYear: {
          year: get(makeModelSubmodelYear, 'year', ''),
          id: get(makeModelSubmodelYear, 'modelYearId', ''),
        },
        subModel: {
          name: get(makeModelSubmodelYear, 'submodels.name', ''),
          niceName: get(makeModelSubmodelYear, 'submodels.slug', ''),
          id: get(makeModelSubmodelYear, 'id', ''),
          adTargetId: get(makeModelSubmodelYear, 'submodels.adTargetId'),
        },
        typeList: Object.keys(get(makeModelSubmodelYear, 'edmundsTypeCategories', {})).join(','),
        type: { niceName: TYPES.find(type => edmundsTypeCategories.includes(type)) },
        publicationState,
      };
}

/**
 * This vehicle is for the context and should only be used for ads and tracking.
 *
 * @param {Object} vehicle
 * @param {Boolean} updateGtm
 * @returns {{type: string, vehicle: Object, updateGtm: Boolean}}
 */
export function updateVehicle(vehicle, updateGtm) {
  return {
    type: UPDATE_VEHICLE,
    vehicle,
    ...(updateGtm ? { updateGtm } : {}),
  };
}

export function updateVehicleByStyleId(styleId, modelYearId, updateGtm) {
  return (dispatch, getState) => {
    if (styleId) {
      return resolveModel(`styles.${styleId}`, VehicleModel)(dispatch, getState)
        .then(() => {
          const modelState = getModelState(getState());
          const vehicle = modelState.get(`styles.${styleId}`, VehicleModel);
          const vehicleForAction = parseVehicleForAction({ ...vehicle, modelYearId });
          return dispatch(updateVehicle(vehicleForAction, updateGtm));
        })
        .catch(() => dispatch(updateVehicle(parseVehicleForAction({}))));
    }
    return Promise.resolve(dispatch(updateVehicle(parseVehicleForAction({}))));
  };
}

export function getVehiclePrices(source) {
  const price = get(source, 'displayPrice');
  const guaranteedPrice = get(source, 'guaranteedPrice') || get(source, 'pricePromise.guaranteedPrice');
  let salePrice;
  if (guaranteedPrice && guaranteedPrice < price) {
    salePrice = guaranteedPrice;
  }
  return { price, salePrice };
}

/**
 * This function is specifically for the inventory v5 response in InventoryModel
 * @param {object} vehicleData
 * @param {object} vehicleMetaData in the format defined as 'MakeModelSubmodelYear' in vehicle model file
 * @returns {object}
 */
export function getVehicleFromInventoryVin({ vin, vehicleInfo = {}, type = '', prices = {} }, vehicleMetaData = {}) {
  const styleInfo = get(vehicleInfo, 'styleInfo', {});
  const exteriorColor = get(vehicleInfo, 'vehicleColors.exterior.genericName', '');
  const interiorColor = get(vehicleInfo, 'vehicleColors.interior.genericName', '');
  const trim = get(styleInfo, 'trim', '');
  const { price, salePrice } = getVehiclePrices(prices);

  return {
    publicationState: getPublicationStateByMultipleTypes(type),
    make: {
      niceName: get(vehicleMetaData, 'make.slug', makeNiceName(get(styleInfo, 'make', ''))),
      name: get(vehicleMetaData, 'make.name', get(styleInfo, 'make', '')),
      id: get(vehicleMetaData, 'make.id', ''),
      adTargetId: get(vehicleMetaData, 'make.adTargetId'),
    },
    modelYear: {
      year: get(vehicleMetaData, 'year', get(styleInfo, 'year', '')),
      id: get(vehicleMetaData, 'modelYearId', get(styleInfo, 'modelId', '')),
    },
    model: {
      niceName: get(vehicleMetaData, 'model.slug', makeNiceName(get(styleInfo, 'model', ''))),
      name: get(vehicleMetaData, 'model.name', get(styleInfo, 'model', '')),
      modelLinkCode: get(vehicleMetaData, 'model.modelLinkCode', ''),
      adTargetId: get(vehicleMetaData, 'model.adTargetId'),
    },
    subModel: {
      niceName: get(vehicleMetaData, 'submodels.slug', ''),
      name: get(vehicleMetaData, 'submodels.name', ''),
      id: get(vehicleMetaData, 'id', ''),
      adTargetId: get(vehicleMetaData, 'submodels.adTargetId'),
    },
    type: {
      niceName: styleInfo.bodyType,
    },
    style: {
      id: styleInfo.styleId,
    },
    displayPrice: price ? price.toString() : '',
    salePrice,
    exteriorColor,
    interiorColor,
    trim,
    vin,
  };
}

/**
 * This function is specifically for the inventory v5 response in InventoryModel
 * currently, adTargetId parameter is empty, it need to update inventory service by attributes.vehicle
 * @param {object} vehicleData
 * @param {object} vehicleMetaData in the format defined as 'Vehicle' in vehicle model file
 * @returns {object}
 */
export function getVehicleFromInventoryAttrs({ vehicleInfo = {}, type = '' }, vehicleMetaData = {}) {
  const styleInfo = get(vehicleInfo, 'styleInfo', {});
  const displayPrice = get(vehicleInfo, 'displayPrice', '');
  const exteriorColor = get(vehicleInfo, 'vehicleColors.exterior.genericName', '');
  const interiorColor = get(vehicleInfo, 'vehicleColors.interior.genericName', '');

  return {
    publicationState: getPublicationStateByMultipleTypes(type),
    make: {
      niceName: get(vehicleMetaData, 'make.niceId', ''),
      name: get(vehicleMetaData, 'make.name', ''),
      id: get(vehicleMetaData, 'make.id', ''),
      adTargetId: get(vehicleMetaData, 'make.adTargetId'),
    },
    modelYear: {
      year: get(vehicleMetaData, 'modelYear.year', styleInfo.year),
      id: get(vehicleMetaData, 'modelYear.id', ''),
    },
    model: {
      niceName: get(vehicleMetaData, 'model.niceId', ''),
      name: get(vehicleMetaData, 'model.name', ''),
      modelLinkCode: get(vehicleMetaData, 'model.modelLinkCode', ''),
      adTargetId: get(vehicleMetaData, 'model.adTargetId'),
    },
    type: {
      niceName: styleInfo.bodyType,
    },
    displayPrice,
    interiorColor,
    exteriorColor,
    trim: styleInfo.trim,
  };
}

export function getVehicleFromSubModels(subModelNiceName, modelYear, subModels, modelYearId, styleId) {
  const subModel = subModels && (subModels[subModelNiceName] || Object.values(subModels)[0]);
  if (!subModel) return null;

  let vehicle = {
    make: {
      name: get(subModel, 'make.name', ''),
      niceName: get(subModel, 'make.slug', ''),
      id: get(subModel, 'make.id', ''),
      adTargetId: get(subModel, 'make.adTargetId'),
    },
    model: {
      name: get(subModel, 'model.name', ''),
      niceName: get(subModel, 'model.slug', ''),
      modelLinkCode: get(subModel, 'model.modelLinkCode', ''),
      adTargetId: get(subModel, 'model.adTargetId'),
    },
    modelYear: {
      year: modelYear.year,
      id: Number(modelYearId || modelYear.id),
    },
    ...(styleId && {
      style: {
        id: Number(styleId),
      },
    }),
  };
  if (subModels[subModelNiceName]) {
    vehicle = {
      ...vehicle,
      subModel: {
        name: get(subModel, 'name', ''),
        niceName: subModelNiceName,
        adTargetId: get(subModel, 'adTargetId'),
        id: get(subModel, `years[${modelYear.year}].id`),
      },
    };
  }
  return vehicle;
}

function getAdTargetId(name) {
  return name.toLowerCase().replace(/[\s-]/g, '');
}

export function getVehicleFromOffer(vehicleInfo) {
  return {
    make: {
      name: get(vehicleInfo, 'make', ''),
      niceName: get(vehicleInfo, 'makeSlug', ''),
      adTargetId: getAdTargetId(get(vehicleInfo, 'make', '')),
    },
    model: {
      name: get(vehicleInfo, 'model', ''),
      niceName: get(vehicleInfo, 'modelSlug', ''),
      adTargetId: getAdTargetId(get(vehicleInfo, 'model', '')),
    },
    modelYear: {
      year: vehicleInfo.year,
    },
    subModel: {
      name: get(vehicleInfo, 'subModel', ''),
      niceName: get(vehicleInfo, 'subModelSlug', ''),
      adTargetId: getAdTargetId(get(vehicleInfo, 'subModel', '')),
    },
  };
}
