import * as SRP from 'site-modules/shared/constants/allowed-seo-srp-request-params';
import * as API from 'site-modules/shared/constants/allowed-inventory-request-params';

import { flow, invert, isString, pick, set, reduce, isArray, uniq } from 'lodash';
import { objectToQueryString } from 'site-modules/shared/utils/string';

/**
 * There is a case when facet value is passed as a string, wrapped with double quotes (").
 * It's made for BE to screen such values as a comma (,) or a plus (+)
 * Example: '"Lightning Silver|218,222,227"'
 *
 * However, there is an issue on FE with commas what should be a part of facet value
 * The purpose of this regexp is to keep values inside double quotes unchanged
 *
 * ,               ','
 * (?=             look ahead to see if there is:
 *  (?:            group, but do not capture (0 or more times):
 *   (?:           group, but do not capture (2 times):
 *    [^"]*        any character except: '"' (0 or more times)
 *    "            '"'
 *   ){2}          end of grouping
 *  )*             end of grouping
 *  [^"]*          any character except: '"' (0 or more times)
 *  $              before an optional \n, and the end of the string
 * )               end of look-ahead
 *
 * Details: https://stackoverflow.com/questions/23582276/split-string-by-comma-but-ignore-commas-inside-quotes/23582323
 * @type {RegExp}
 */
const FACET_VALUE_DETECTION_REGEXP = new RegExp(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);

/**
 * A map of key-value pairs between SEO (lowercase) and API (camelCase) parameters
 * Useful for decoding SEO parameters to standard API parameters
 * See constants imported for further details
 *
 * @example
 * { type: 'inventoryType' }
 */
export const SEO_TO_API_MAP = reduce(
  SRP,
  (accumulator, constValue, constName) => {
    const srpEquivalent = SRP[constName];
    const apiEquivalent = API[constName];

    if (isString(constName) && srpEquivalent && apiEquivalent) {
      set(accumulator, srpEquivalent, apiEquivalent);
    }

    return accumulator;
  },
  {}
);

/**
 * A map of pairs between API and SEO parameters
 * Useful for creating a list of SEO params based on list of selected facets
 *
 * @example
 * { inventoryType: 'type' }
 */
export const API_TO_SEO_MAP = invert(SEO_TO_API_MAP);

/**
 * A common method that helps to transform all known object keys based on predefined map
 * Keeps unknown parameters unchanged (useful for service query params)
 *
 * @param { Object} source
 * @param {Object} keysMap one of predefined maps (SEO_TO_API_MAP, API_TO_SEO_MAP)
 * @returns {Object}
 */
function replaceKeys(source, keysMap) {
  return reduce(
    source,
    (accumulator, value, name) => {
      set(accumulator, keysMap[name] || name, value);
      return accumulator;
    },
    {}
  );
}

/**
 * Replaces query parameters to standard API-compatible parameters
 *
 * @example
 * // returns { exteriorColor: 'Red', debug: true }
 * replaceToApiKeys({ extcolor: 'Red', debug: true })
 *
 * @param {Object} source Map of query parameters
 * @returns {Object} Mal of API parameters
 */
export function replaceToApiKeys(source) {
  return replaceKeys(source, SEO_TO_API_MAP);
}

/**
 * Replaces API-compatible parameters with SEO parameters
 *
 * @example
 * // returns { extcolor: 'Red', wrongparam: true }
 * replaceToApiKeys({ exteriorColor: 'Red', wrongparam: true })
 *
 * @param {Object} source Map of API parameters
 * @returns {Object} Map of query parameters
 */
export function replaceToSeoKeys(source) {
  return replaceKeys(source, API_TO_SEO_MAP);
}

/**
 * A common method for leaving only known parameters in an object
 * @param {Object} source
 * @param {Object} keysList one of predefined maps (SEO_TO_API_MAP, API_TO_SEO_MAP)
 * @returns {Object}
 */
function filterAvailableKeys(source, keysList) {
  return pick(source, keysList);
}

/**
 * Leaves only known SEO keys
 *
 * @example
 * // returns { extcolor: 'Red' }
 * filterAvailableSeoKeys({ extcolor: 'Red', interiorColor: 'Blue', debug: true })
 *
 * @param {Object} source Object to filter
 * @returns {Object} Filtered object
 */
export function filterAvailableSeoKeys(source) {
  return filterAvailableKeys(source, SRP.ALLOWED_SEO_SRP_REQUEST_PARAMS);
}

/**
 * Leaves only known API keys
 *
 * @example
 * // returns { interiorColor: 'Blue' }
 * filterAvailableApiKeys({ extcolor: 'Red', interiorColor: 'Blue', debug: true })
 *
 * @param {Object} source Object to filter
 * @returns {Object} Filtered object
 */
export function filterAvailableApiKeys(source) {
  return filterAvailableKeys(source, API.ALLOWED_INVENTORY_REQUEST_PARAMS);
}

/**
 * Helps to split multiple values of one SEO key
 *
 * @example
 * // returns { extcolor: ['Blue', Red, 'Yellow', '"Lightning Silver|218,222,227"'] }
 * prepareSelectedFiltersMap({extcolor: 'Blue,Red,Red,Yellow,"Lightning Silver|218,222,227"'})
 *
 * @param {Object} params
 * @returns {Object}
 */
function prepareSelectedFiltersMap(params) {
  return reduce(
    params,
    (increment, value, key) => {
      set(increment, key, uniq(value.split(FACET_VALUE_DETECTION_REGEXP)));
      return increment;
    },
    {}
  );
}

/**
 * Opposite to `defineSelectedFilters`
 * Takes list of selected facets and transforms it into object of query params
 *
 * @example
 * // returns { make: 'honda', intcolor: 'blue,red', national: true }
 * prepareQueryParamsMap({ make: ['honda', intcolor: ['blue', 'red'], national: true] })
 *
 * @param {Object} params Selected facets map
 * @returns {Object} Map of queries
 */
function prepareQueryParamsMap(params) {
  return reduce(
    params,
    (accumulator, value, key) => {
      const inlineValue = isArray(value) ? value.join() : value;

      if (inlineValue) {
        set(accumulator, key, inlineValue);
      }

      return accumulator;
    },
    {}
  );
}

/**
 * Takes query params and transforms them into list of selected facets
 *
 * @example
 * // returns { interiorColor: ['Red', 'Blue'], make: ['honda'] }
 * defineSelectedFilters({ debug: true, intcolor: 'Red,Blue', make: 'honda' })
 *
 * @param {Object} rawParams Query params
 * @returns {Object}
 */
export function defineSelectedFilters(rawParams) {
  return flow(
    prepareQueryParamsMap,
    filterAvailableSeoKeys,
    replaceToApiKeys,
    prepareSelectedFiltersMap
  )(rawParams);
}

/**
 * Prepares a query string from on a list of selected facets
 *
 * @example
 * // returns 'extcolor=Red,Blue&make=honda&debug=true'
 * getBrowserQuery({ exteriorColor: ['Red', 'Blue'], make: 'honda', debug: true })
 *
 * @param params
 * @returns {*}
 */
export function getBrowserQuery(params) {
  return flow(
    replaceToSeoKeys,
    prepareQueryParamsMap,
    objectToQueryString
  )(params);
}

/**
 * Prepares a map with selected filters to be sent on the server
 *
 * @example
 * // returns 'exteriorColor=Red,Blue&make=honda'
 * getSearchResultsQuery({ exteriorColor: ['Red', 'Blue'], make: 'honda', wrongparam: true })
 *
 * @param params
 * @returns {*}
 */
export function getSearchResultsQuery(params) {
  return flow(
    filterAvailableApiKeys,
    prepareQueryParamsMap,
    objectToQueryString
  )(params);
}
