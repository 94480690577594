import { srpLinkBuilder } from 'site-modules/shared/utils/srp-link-constructor';

export const HOME_BREADCRUMB_TITLE = 'Home';
const SRP_LANDING_BREADCRUMB_TITLE = 'Cars For Sale';
const USED_LANDING_BREADCRUMB_TITLE = 'Used Cars For Sale';

/**
 * Create structure for breadcrumb item
 * @param title
 * @param [href]
 * @param attrs
 * @returns {{title: *, href: *, attrs: object}}
 */
export function getBaseBreadcrumb(title, href, attrs) {
  return {
    title,
    href,
    attrs,
  };
}

/**
 * Most commonly used first breadcrumb in list: Home, leading to home page
 *
 * @return {{title: string, href: string}}
 */
export function getHomeBreadcrumb() {
  return getBaseBreadcrumb(HOME_BREADCRUMB_TITLE, '/');
}

/**
 * A common breadcrumb for inventory pages.
 * Leads to Used Landing page.
 * @returns {{title: string, href: string}}
 */
export function getUsedLandingBreadcrumb() {
  return getBaseBreadcrumb(USED_LANDING_BREADCRUMB_TITLE, '/used-cars-for-sale/');
}

/**
 * A common breadcrumb for inventory pages.
 * Leads to SRP Inventory page.
 * @returns {object[]}
 */
export function getCarsForSaleBreadcrumbs() {
  return [getHomeBreadcrumb(), getBaseBreadcrumb(SRP_LANDING_BREADCRUMB_TITLE, srpLinkBuilder())];
}
