import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { compact, get } from 'lodash';
import { AdSlot, DFPManager } from '@edmunds/react-dfp';
import {
  handleAdLoad,
  fireHeavyAdInterventionEvent,
} from 'client/engagement-handlers/ads-engagement-handler/ads-engagement-handler';
import { RefreshRules } from 'client/site-modules/shared/components/ad-unit/utils/refresh-rules';
import { ClientSideRender } from 'site-modules/shared/components/client-side-render/client-side-render';
import { isRenderedAdUnit } from 'client/utils/ads';
import { pushToBidEligibleAdUnits } from 'client/utils/pwt/get-bid-eligible-array';

import { GPT_NETWORK_CODE } from './context/sitename';
import { AdUnitPropTypes, AdUnitDefaultProps } from './ad-unit-prop-types';
import {
  getAdSizes,
  getTargeting,
  getSitename,
  getClassNames,
  refreshAdUnit,
  isPwtWhitelistAd,
} from './ad-unit-helper';
import { AdsPositionManager } from './ads-position-manager';

import './ad-unit.scss';

export class AdUnitSlot extends Component {
  constructor(props) {
    super(props);

    const { adName, nativeStyle, position } = props;

    this.state = {
      isRenderPlaceholderComponent: false,
      isShowLabel: this.props.showLabel,
      lineItemId: '',
    };

    this.adZonePosition = AdsPositionManager.getInstance().getPosition(adName, nativeStyle, position);
  }

  componentDidMount() {
    const { pwtEnabled, adName } = this.props;

    // IMPORTANT: PWT can not work correctly on pages with continues scroll: position value can be changed
    if (pwtEnabled && isPwtWhitelistAd(adName)) {
      const sizes = getAdSizes(this.props, false).filter(size => Array.isArray(size) && size[0] > 2);
      pushToBidEligibleAdUnits({
        id: `${adName}_${this.props.position}`,
        dfpNetworkId: GPT_NETWORK_CODE,
        slotId: this.getSlotId(),
        adUnitId: `/${GPT_NETWORK_CODE}/${getSitename(this.props)}`,
        adUnitIndex: `${this.props.position - 1}`,
        sizes,
      });
    }

    window.addEventListener('message', this.messageListener, false);
  }

  componentDidUpdate(prevProps) {
    const { vehicleContextChange, visitor, adName, refreshDisableWhenZipChanged } = this.props;
    const prevZipCode = prevProps.visitor.location.zipCode;
    const zipCode = visitor.location.zipCode;

    if (!refreshDisableWhenZipChanged && !vehicleContextChange && prevZipCode !== zipCode) {
      // When zip code is changed, ads should be refreshed. The refresh action is suppressed for site-served ads.
      refreshAdUnit(adName, this.getSlotId());
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.messageListener, false);
  }

  onSlotRegister = event => {
    if (this.props.onSlotRegister) {
      this.props.onSlotRegister(event);
    }
    const { pwtEnabled, adName, skipPwtRefresh } = this.props;

    if (pwtEnabled && window?.PWT?.adsPwtLoaded) {
      refreshAdUnit(adName, this.getSlotId(), skipPwtRefresh);
    }
  };

  onSlotRender = ({ event }) => {
    const { placeholderComponent, showLabel, slotRenderEndListener, refreshable } = this.props;

    this.setState({
      lineItemId: event.lineItemId,
      isRenderPlaceholderComponent: placeholderComponent && !isRenderedAdUnit(event),
      isShowLabel: showLabel && isRenderedAdUnit(event),
    });

    if (slotRenderEndListener && typeof slotRenderEndListener === 'function') {
      slotRenderEndListener(event);
    }

    if (refreshable) {
      RefreshRules.addToRefreshableUnitsArray(this.getSlotId(), this.props);
    }

    handleAdLoad(event);
  };

  onSlotVisibilityChanged = ({ event }) => {
    const { inViewPercentage } = event;
    if (this.props.refreshWhenViewable) {
      if (inViewPercentage === 100 && this.previousVisability !== 0) {
        if (this.wasShowedOnce && this.wasHidden) {
          DFPManager.refresh(this.getSlotId());
          this.wasHidden = false;
        }
        this.wasShowedOnce = true;
      } else if (inViewPercentage === 0 && this.previousVisability !== 100) {
        this.wasHidden = true;
      }
      this.previousVisability = inViewPercentage;
    }
  };

  getPosition = () => {
    const { hasAdZone, isIncrementPosition, position } = this.props;
    return hasAdZone && isIncrementPosition ? this.adZonePosition : position;
  };

  getSlotId = () => {
    const { prefixId, adName, nativeStyle, page, customTargeting } = this.props;
    return compact([prefixId, adName, nativeStyle, `${this.getPosition()}`, page.name, get(customTargeting, 'sect8')])
      .join('-')
      .replace(',', '_');
  };

  messageListener = ({ data }) => {
    const { lineItemId } = this.state;
    const messageId = get(data, 'body.id', '');
    const messageLineItemId = get(data, 'lineItemId', '');

    if (messageId === 'HeavyAdIntervention' && lineItemId === messageLineItemId) {
      fireHeavyAdInterventionEvent(lineItemId);
    }
  };

  render() {
    const { adName, nativeStyle, placeholderComponent: PlaceholderComponent, placeholderComponentProps } = this.props;
    const { isRenderPlaceholderComponent } = this.state;

    const containerProps = {
      className: getClassNames(this.props, this.state),
      'data-adname': adName,
      'data-native-style': nativeStyle,
    };

    return (
      <Fragment>
        <div {...containerProps}>
          <ClientSideRender showTimeout={1}>
            <AdSlot
              dfpNetworkId={GPT_NETWORK_CODE}
              slotId={this.getSlotId()}
              adUnit={getSitename(this.props)}
              sizes={getAdSizes(this.props, false)}
              targetingArguments={getTargeting({
                ...this.props,
                position: this.getPosition(),
              })}
              onSlotRender={this.onSlotRender}
              onSlotVisibilityChanged={this.onSlotVisibilityChanged}
              onSlotRegister={this.onSlotRegister}
            />
          </ClientSideRender>
        </div>
        {isRenderPlaceholderComponent && <PlaceholderComponent {...placeholderComponentProps} />}
        {/* todo shouldn't placeholder be rendered on both server and client to avoid flickers */}
      </Fragment>
    );
  }
}

AdUnitSlot.propTypes = {
  ...AdUnitPropTypes,
  prefixId: PropTypes.string,
  hasAdZone: PropTypes.bool,
  isIncrementPosition: PropTypes.bool,
};

AdUnitSlot.defaultProps = {
  ...AdUnitDefaultProps,
  prefixId: undefined,
  hasAdZone: false,
  isIncrementPosition: false,
};
