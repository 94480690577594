import {
  EXTERIOR_COLOR,
  HISTORY_INFO,
  EXCLUDE_HISTORY_INFO,
  DEAL_TYPE,
  DELIVERY_TYPE,
  FEATURE,
  INTERIOR_COLOR,
  CYLINDERS_COUNT,
  TRANSMISSION,
  DRIVE_TRAIN,
  OPTION,
  TRIM,
  YEAR,
  MILEAGE,
  MAKE,
  MODEL,
  RADIUS,
  SORT_BY,
  BODY_TYPE,
  INVENTORY_TYPE,
  ZIP_CODE,
  DISPLAY_PRICE,
  LEASE_PAYMENT,
  LOAN_PAYMENT,
  PAGE_NUMBER,
  ROOFTOP_ID,
  PAYMENT_TYPE,
  RECENTLY_LISTINGS,
  PRICE_DROP,
  COMBINE_MPG,
  ELECTRICITY_RANGE,
  TRUCK_CAB_SIZE,
  BED_LENGTH,
  DISPLACEMENT,
  REAR_WHEELS,
  ENGINE_TYPE,
  INITIAL_URL_PATTERN,
  WITH_PHOTO,
  WITH_PRICE,
} from 'site-modules/shared/constants/allowed-inventory-request-params';
import { MILEAGE_DESC, MILEAGE_ASC } from 'site-modules/shared/constants/sort-types';
import { isUsedInventoryTypeSelected } from 'site-modules/shared/utils/inventory/srp-utils';

export const PRICE_TABS = 'pricetabs';
export const ENGINE_MECHANICS_GROUP = 'engine_mechanics_group';
export const VEHICLE_HISTORY_GROUP = 'vehicle_history_group';
export const VEHICLE_LISTING_DETAILS_GROUP = 'vehicle_listing_details_group';
export const MAKE_MODEL_GROUP = 'make_model_group';
export const TRUCK_DETAILS_GROUP = 'truck_details_group';
export const MILES = 'miles';
export const CURRENCY = 'currency';
export const MULTIPLE_VALUE = '*';
export const PLUS_SIGN = '+';
export const NO_MIN_VALUE = 'Up to ';
export const NO_MAX_VALUE = PLUS_SIGN;
export const MPG = 'MPG';
export const MI = 'mi';

export const FACETS_CONTROLS = {
  CHECKBOX: 'checkbox',
  CHECKBOX_GROUPS: 'checkbox_groups',
  RANGE: 'range',
  TABS: 'tabs',
  SELECT_GROUPS: 'select_groups',
  SELECT: 'select',
  RADIUS_SLIDER: 'radius_slider',
  SWITCH: 'switch',
};

export const facetsConfig = {
  [MAKE]: {
    title: 'Search by Make',
    control: FACETS_CONTROLS.SELECT,
    group: MAKE_MODEL_GROUP,
    toggle: 'Any Make',
  },
  [MODEL]: {
    title: 'Search by Model',
    control: FACETS_CONTROLS.SELECT,
    group: MAKE_MODEL_GROUP,
    toggle: 'Any Model',
  },
  [INVENTORY_TYPE]: {
    title: 'Condition',
    creativeId: 'condition',
    shortName: 'cond',
    control: FACETS_CONTROLS.CHECKBOX,
    mobileOnly: true,
    order: 3,
  },
  [BODY_TYPE]: {
    title: 'Type',
    control: FACETS_CONTROLS.CHECKBOX,
    creativeId: 'type',
    shortName: 'type',
    order: 7,
  },
  [EXTERIOR_COLOR]: {
    title: 'Exterior Colors',
    control: FACETS_CONTROLS.CHECKBOX,
    creativeId: 'exterior_colors',
    shortName: 'exte',
    order: 13,
  },
  [HISTORY_INFO]: {
    title: '',
    group: VEHICLE_HISTORY_GROUP,
  },
  [EXCLUDE_HISTORY_INFO]: {
    title: 'Exclude vehicles with',
    group: VEHICLE_HISTORY_GROUP,
  },
  [DEAL_TYPE]: {
    title: 'Edmunds Deal Rating',
    control: FACETS_CONTROLS.CHECKBOX,
    creativeId: 'edmunds_deal_rating',
    shortName: 'deal',
    order: 12,
  },
  [FEATURE]: {
    title: 'Features',
    control: FACETS_CONTROLS.CHECKBOX,
    creativeId: 'features',
    shortName: 'feat',
    order: 14,
  },
  [INTERIOR_COLOR]: {
    title: 'Interior Colors',
    control: FACETS_CONTROLS.CHECKBOX,
    creativeId: 'interior_colors',
    shortName: 'inte',
    order: 19,
  },
  [CYLINDERS_COUNT]: {
    title: 'Cylinders',
    control: FACETS_CONTROLS.CHECKBOX,
    group: ENGINE_MECHANICS_GROUP,
    order: 4,
  },
  [TRANSMISSION]: {
    title: 'Transmission',
    control: FACETS_CONTROLS.CHECKBOX,
    group: ENGINE_MECHANICS_GROUP,
    order: 1,
  },
  [DRIVE_TRAIN]: {
    title: 'Drivetrain',
    control: FACETS_CONTROLS.CHECKBOX,
    group: ENGINE_MECHANICS_GROUP,
    order: 3,
  },
  [ENGINE_TYPE]: {
    title: 'Engine Type',
    control: FACETS_CONTROLS.CHECKBOX,
    group: ENGINE_MECHANICS_GROUP,
    order: 2,
  },
  [OPTION]: {
    title: 'Option & Packages',
    control: FACETS_CONTROLS.CHECKBOX,
    creativeId: 'options_and_packages',
    shortName: 'opti',
    order: 20,
  },
  [TRIM]: {
    title: 'Trims',
    control: FACETS_CONTROLS.CHECKBOX,
    creativeId: 'trim',
    shortName: 'trim',
    order: 9,
  },
  [YEAR]: {
    title: 'Year(s)',
    control: FACETS_CONTROLS.RANGE,
    creativeId: 'year',
    shortName: 'year',
    order: 4,
  },
  [MILEAGE]: {
    title: 'Mileage',
    control: FACETS_CONTROLS.RANGE,
    labelType: MILES,
    creativeId: 'mileage',
    shortName: 'mile',
    order: 10,
  },
  [DISPLAY_PRICE]: {
    title: 'Price',
    control: FACETS_CONTROLS.RANGE,
    group: PRICE_TABS,
    labelType: CURRENCY,
    order: 3,
  },
  [LOAN_PAYMENT]: {
    title: 'Loan Payment',
    control: FACETS_CONTROLS.SELECT,
    group: PRICE_TABS,
    labelType: CURRENCY,
    disclaimer: 'Assumes no money down and a 60-month term. Click a vehicle for more details.',
    order: 1,
    toggleTo: '$ Max',
    prefix: 'Up to',
    postfix: 'per month',
  },
  [LEASE_PAYMENT]: {
    title: 'Lease Payment',
    control: FACETS_CONTROLS.SELECT,
    group: PRICE_TABS,
    labelType: CURRENCY,
    disclaimer: 'Assumes $1K due at signing. Click a vehicle for more details.',
    order: 2,
    toggleTo: '$ Max',
    prefix: 'Up to',
    postfix: 'per month',
  },
  [DELIVERY_TYPE]: {
    title: 'Delivery',
    control: FACETS_CONTROLS.CHECKBOX,
    hideCount: true,
    creativeId: 'available_for_delivery',
    shortName: 'avail',
    order: 5,
  },
  [RECENTLY_LISTINGS]: {
    group: VEHICLE_LISTING_DETAILS_GROUP,
    control: FACETS_CONTROLS.CHECKBOX,
  },
  [PRICE_DROP]: {
    group: VEHICLE_LISTING_DETAILS_GROUP,
    control: FACETS_CONTROLS.CHECKBOX,
  },
  [WITH_PHOTO]: {
    group: VEHICLE_LISTING_DETAILS_GROUP,
    control: FACETS_CONTROLS.CHECKBOX,
  },
  [WITH_PRICE]: {
    group: VEHICLE_LISTING_DETAILS_GROUP,
    control: FACETS_CONTROLS.CHECKBOX,
  },
  [COMBINE_MPG]: {
    title: 'Fuel Economy',
    control: FACETS_CONTROLS.RANGE,
    creativeId: 'combinedMPG',
    shortName: 'fuel',
    labelType: MPG,
    order: 16,
  },
  [ELECTRICITY_RANGE]: {
    title: 'Electric Range',
    control: FACETS_CONTROLS.RANGE,
    creativeId: 'electricityRange',
    shortName: 'electric',
    labelType: MI,
    order: 17,
  },
  [TRUCK_CAB_SIZE]: {
    title: 'Cabin Size',
    control: FACETS_CONTROLS.CHECKBOX,
    group: TRUCK_DETAILS_GROUP,
  },
  [BED_LENGTH]: {
    title: 'Bed Length',
    control: FACETS_CONTROLS.CHECKBOX,
    group: TRUCK_DETAILS_GROUP,
  },
  [DISPLACEMENT]: {
    title: 'Displacement',
    control: FACETS_CONTROLS.CHECKBOX,
    group: TRUCK_DETAILS_GROUP,
  },
  [REAR_WHEELS]: {
    title: 'Rear Wheels',
    control: FACETS_CONTROLS.CHECKBOX,
    group: TRUCK_DETAILS_GROUP,
  },
};

const GENERAL_EXCLUDE_RESET_FACETS = [
  INVENTORY_TYPE,
  SORT_BY,
  RADIUS,
  ZIP_CODE,
  ROOFTOP_ID,
  DISPLAY_PRICE,
  LEASE_PAYMENT,
  LOAN_PAYMENT,
];

export const EXCLUDE_RESET_FACETS_BY = {
  [MAKE]: GENERAL_EXCLUDE_RESET_FACETS,
  [MODEL]: GENERAL_EXCLUDE_RESET_FACETS.concat([MAKE]),
};

// Facet values that should be excluded from filters change removing.
// `value` which value should be excluded from remove.
// `exceptions` which facets should be excluded from this check.
export const EXCLUDE_VALUES_FROM_FILTER = {
  [RADIUS]: {
    value: '6000',
    exceptions: [MODEL],
  },
};

// Facets that should be checked when they values are changing and which should
// exclude another facet when this facet has conflicts with selected.
// `facetNameToExclude` property contains facet that should be excluded
// `valuesToRemove` property includes values that should be checked in facet for its exclude
// `checkCallback` callback that should check that we should leave selected facets as is
export const EXCLUDE_FACET_BY_CHECKING_VALUE = {
  [INVENTORY_TYPE]: {
    facetNameToExclude: SORT_BY,
    valuesToRemove: [MILEAGE_DESC, MILEAGE_ASC],
    checkCallback: isUsedInventoryTypeSelected,
  },
};

export const RESET_FACETS_BY = {
  [MAKE]: [LEASE_PAYMENT, LOAN_PAYMENT, DISPLAY_PRICE],
  [MODEL]: [LEASE_PAYMENT, LOAN_PAYMENT, DISPLAY_PRICE],
  [INVENTORY_TYPE]: [LEASE_PAYMENT, LOAN_PAYMENT, PAYMENT_TYPE],
  [LEASE_PAYMENT]: [LOAN_PAYMENT, DISPLAY_PRICE, PAYMENT_TYPE],
  [LOAN_PAYMENT]: [LEASE_PAYMENT, DISPLAY_PRICE, PAYMENT_TYPE],
  [PAYMENT_TYPE]: [LEASE_PAYMENT, LOAN_PAYMENT, DISPLAY_PRICE],
  [DISPLAY_PRICE]: [LEASE_PAYMENT, LOAN_PAYMENT],
  [BODY_TYPE]: [TRUCK_CAB_SIZE, BED_LENGTH, DISPLACEMENT, REAR_WHEELS],
  [YEAR]: [INITIAL_URL_PATTERN],
  [SORT_BY]: [INITIAL_URL_PATTERN],
};

export const RESET_FACETS_BY_FOR_MULTI_MM = {
  [INVENTORY_TYPE]: [LEASE_PAYMENT, LOAN_PAYMENT, PAYMENT_TYPE],
  [LEASE_PAYMENT]: [LOAN_PAYMENT, DISPLAY_PRICE, PAYMENT_TYPE],
  [LOAN_PAYMENT]: [LEASE_PAYMENT, DISPLAY_PRICE, PAYMENT_TYPE],
  [PAYMENT_TYPE]: [LEASE_PAYMENT, LOAN_PAYMENT, DISPLAY_PRICE],
  [DISPLAY_PRICE]: [LEASE_PAYMENT, LOAN_PAYMENT],
  [BODY_TYPE]: [TRUCK_CAB_SIZE, BED_LENGTH, DISPLACEMENT, REAR_WHEELS],
  [YEAR]: [INITIAL_URL_PATTERN],
  [SORT_BY]: [INITIAL_URL_PATTERN],
};

export const EXCLUDE_FROM_RESET_FACETS = [ROOFTOP_ID, RADIUS]; // Array with facets which not reset on `Clear All` button

// Facets that always should be reset.
// `rules` property contains facets that should be reset in any case.
// `exceptions` property contains facets that should be escaped from reset.
export const RESET_FACETS = {
  rules: [PAGE_NUMBER],
  exceptions: [PAYMENT_TYPE],
};

export const SINGLE_FACETS = [
  MAKE,
  MODEL,
  RADIUS,
  SORT_BY,
  DISPLAY_PRICE,
  MILEAGE,
  LOAN_PAYMENT,
  LEASE_PAYMENT,
  PAYMENT_TYPE,
  YEAR,
  ROOFTOP_ID,
  COMBINE_MPG,
  ELECTRICITY_RANGE,
];
export const SINGLE_FACETS_FOR_MULTI_MM = [
  RADIUS,
  SORT_BY,
  DISPLAY_PRICE,
  MILEAGE,
  LOAN_PAYMENT,
  LEASE_PAYMENT,
  PAYMENT_TYPE,
  YEAR,
  ROOFTOP_ID,
  COMBINE_MPG,
  ELECTRICITY_RANGE,
];

// Facets whose values are combined
// E.g.: facet with values where names are the same, but counts and values are different,
// so we need to combine them all into one and use combined value ('test1,test2').
// See `combineFacetValues()` function in client/site-modules/shared/utils/inventory/search-filter.js
export const COMBINED_FACETS = [TRANSMISSION];
export const EDITORIAL_RATING_TO_SHOW = ['Great', 'Good'];
