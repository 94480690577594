import {
  isUndefined,
  get,
  omit,
  omitBy,
  unionWith,
  unset,
  without,
  set,
  sortBy,
  flow,
  defaultTo,
  compact,
  flatMap,
  uniqBy,
} from 'lodash';
import find from 'lodash/fp/find';
import filter from 'lodash/fp/filter';
import { defineSelectedFilters } from 'site-modules/shared/utils/inventory/params-conversion';
import { makeNiceName } from 'client/site-modules/shared/utils/nice-name';
import {
  EXCLUDE_RESET_FACETS_BY,
  EXCLUDE_VALUES_FROM_FILTER,
  RESET_FACETS_BY,
  RESET_FACETS,
  SINGLE_FACETS,
  EXCLUDE_FROM_RESET_FACETS,
  EXCLUDE_FACET_BY_CHECKING_VALUE,
  COMBINED_FACETS,
  SINGLE_FACETS_FOR_MULTI_MM,
  RESET_FACETS_BY_FOR_MULTI_MM,
} from 'site-modules/shared/constants/inventory/facets-config';
import { isUsedInventoryTypeSelected, isNewInventoryTypeSelected } from 'site-modules/shared/utils/inventory/srp-utils';
import * as SEO from 'site-modules/shared/constants/allowed-seo-srp-request-params';
import * as API from 'site-modules/shared/constants/allowed-inventory-request-params';
import {
  isUsedYMMCityStateSRP,
  isSrpOnUsedCorePage,
  isCoreSrpMMYS,
} from 'site-modules/shared/utils/inventory/srp-type-checkers';
import { NATIONWIDE_VALUE } from 'site-modules/shared/constants/inventory/static-facets';
import { expandedRadiusDueToLowResultsSort } from 'site-modules/shared/constants/sort-types';
import { isPartnerDealer } from 'site-modules/shared/utils/dealer-details-utils';
import { getMakeName, getModelName, getModelFamilyName } from 'site-modules/shared/utils/inventory/multi-make-model';

const DEFAULT_OBJECT = {};
const EMPTY_VALUE = '*';
const EMPTY_FILTER = [`${EMPTY_VALUE}-${EMPTY_VALUE}`];
const NUMBER_REGEX = /^\d{1,}$/;
const RANGE_REGEX = /^(\*|\d+)-(\*|\d+)$/;

export const PERSIST_URL_PATHNAME_FILTERS = [API.SORT_BY];

/**
 * Gets search filters values from vehicle data object.
 * @param {Object} vinData
 * @param {Number} pageSize
 * @returns {Object}
 */
export function getSearchFilterValues(vinData, pageSize = 10) {
  if (!get(vinData, 'vin')) {
    return DEFAULT_OBJECT;
  }

  const makeName = get(vinData, 'vehicleInfo.styleInfo.make');
  const modelName = get(vinData, 'vehicleInfo.styleInfo.model');
  const rooftopId = get(vinData, 'dealerInfo.rooftopId');

  return {
    bodyType: {
      name: get(vinData, 'vehicleInfo.styleInfo.bodyType', ''),
    },
    make: {
      name: makeName,
      niceName: makeNiceName(makeName),
    },
    model: {
      name: modelName,
      niceName: makeNiceName(modelName),
    },
    modelYear: {
      year: get(vinData, 'vehicleInfo.styleInfo.year'),
    },
    rooftopId: isPartnerDealer(vinData) ? rooftopId : null,
    type: get(vinData, 'type'),
    pageSize,
    responseExclusion: '-dealerInfo.productFeatures.directDealer=false',
  };
}

function setSelected(facet, selectedFacet) {
  return { ...facet, selected: selectedFacet.includes(facet.value) || selectedFacet.includes(facet.name) };
}

/**
 * Get facets that contain the selected and unselected values
 * @param facets All facets
 * @param selectedFacets
 * @returns {*}
 */
export function getAllFacetsBySelectedValues(facets, selectedFacets) {
  return facets
    .filter(facet => selectedFacets[facet.type])
    .map(({ type, values = [], groups, min, max, showAboveUpperBoundValue, lowerBound, upperBound }) => {
      const selected = selectedFacets[type];
      return omitBy(
        {
          type,
          groups:
            groups &&
            Object.fromEntries(
              Object.entries(groups).map(([groupName, groupValues]) => [
                groupName,
                groupValues.map(groupValue => setSelected(groupValue, selected)),
              ])
            ),
          values: values.map(value => setSelected(value, selected)),
          min,
          max,
          selected: selected && !!(min || max), // special case for range facet type, witch does not have values
          lowerBound,
          upperBound,
          showAboveUpperBoundValue,
        },
        isUndefined
      );
    });
}

/**
 * Combine all values into one.
 * Can be used in case when we have facet with values where names are the same, but counts and values are different,
 * so we need to combine them all into one and use combined value ('test1,test2').
 * @param {object[]} values
 */
export function combineFacetValues(values) {
  const combinedValues = values.reduce(
    (acc, { name, count, value, selected }) => {
      acc[0].name = name || acc[0].name; // in case if name is empty, use previous name
      acc[0].count += count || 0; // in case if count is empty, sum up with 0 count
      acc[0].value.push(value); // push value into array for combine them later
      acc[0].selected = !!selected || acc[0].selected; // combined value should be selected if any was selected

      return acc;
    },
    [
      {
        name: '',
        count: 0,
        value: [],
        selected: false,
      },
    ]
  );

  // filter out invalid values and join them with ',' as separator
  // e.g. ['', NaN, 'test1', false, '','', 'test2'] -> 'test1,test2'
  combinedValues[0].value = combinedValues[0].value.filter(Boolean).join(',');

  return combinedValues;
}

function hasSelectedValues(facet) {
  const { values, groups } = facet;

  if (groups && Object.entries(groups).some(([, groupValues]) => groupValues.some(value => value.selected))) {
    return true;
  }

  return values.some(value => value.selected);
}

const removeDuplicates = facets =>
  facets.reduce((facetsSet, facet) => {
    if (facetsSet.find(facetFromSet => facetFromSet.value === facet.value)) {
      return facetsSet;
    }

    return [...facetsSet, facet];
  }, []);

/**
 * Get facets that contain only selected values
 * @param facets
 * @returns {Array}
 */
export function getFacetsBySelectedValues(facets) {
  return facets
    .filter(facet => facet.selected || hasSelectedValues(facet))
    .map(({ type, values = [], groups, min, max, lowerBound, upperBound, showAboveUpperBoundValue, selected }) => {
      const cleanedFacet = omitBy(
        {
          type,
          groups:
            groups &&
            Object.fromEntries(
              Object.entries(groups).map(([groupName, groupValues]) => [
                groupName,
                removeDuplicates(groupValues.filter(value => value.selected)),
              ])
            ),
          values: values.filter(value => value.selected),
          min,
          max,
          selected,
          lowerBound,
          upperBound,
          showAboveUpperBoundValue,
        },
        isUndefined
      );

      if (COMBINED_FACETS.includes(type)) {
        return { ...cleanedFacet, values: combineFacetValues(cleanedFacet.values) };
      }

      return cleanedFacet;
    });
}

function getFacetValues(facet) {
  const { values, groups, type } = facet;

  if (groups) {
    return flatMap(Object.entries(facet.groups), ([groupName, groupValues]) =>
      groupValues.map(value => ({
        ...value,
        group: groupName,
        type,
      }))
    );
  }

  return values.map(value => ({ ...value, type }));
}

function flatFacets(facets) {
  return flatMap(facets, facet => {
    const { min, max } = facet;
    return min || max ? [facet, ...getFacetValues(facet)] : getFacetValues(facet);
  });
}

/**
 * Takes list of facets, filters out radius facet and returns flat list
 * @param facets - list of facets
 * @returns {Array} - flat list of selected facets without radius facet
 */
export function transformSelectedFacetsForDisplaying(facets) {
  return flatFacets(facets.filter(({ type }) => type !== 'radius'));
}

function remainingTrimsAfterRemove({ modelsToRemove, facets, updatedFacets, isSHOP1857Enabled }) {
  const trimsToRemove = modelsToRemove.reduce((trims, modelValue) => {
    const makeNice = modelValue && modelValue.split('|')[0];
    const makeName = getMakeName({ facets, make: makeNice });
    const modelName = getModelName({ facets, makeName, model: modelValue });
    const trimFacet = facets.find(({ type: facetType }) => facetType === API.TRIM);
    const mfn = getModelFamilyName({ facets, makeName, model: modelValue });
    const modelFacet = facets.find(({ type: facetType }) => facetType === API.MODEL);
    const mfnChildren = modelFacet?.groups?.[makeName]?.filter(
      model => model.isChild && model.modelFamilyName === mfn && !updatedFacets[API.MODEL]?.includes(model.value)
    );

    if (isSHOP1857Enabled && mfnChildren?.length) {
      return uniqBy(mfnChildren, 'value').reduce((childTrims, model) => {
        const makeModelTrimGroup = trimFacet?.groups?.[`${makeName}|${model.name}`] ?? [];
        return [...childTrims, ...makeModelTrimGroup.map(({ value }) => value)];
      }, []);
    }

    const makeModelTrimGroup = get(trimFacet, `groups[${makeName}|${modelName}]`, []);

    return [...trims, ...makeModelTrimGroup.map(({ value }) => value)];
  }, []);

  return updatedFacets[API.TRIM].filter(trim => !trimsToRemove.includes(trim));
}

/**
 * Get new filter for searching
 * @param {string} type facet type
 * @param {object} facet facet values
 * @param {boolean} isChecked turn on/off facet
 * @param {object} currentFilter current filter
 * @param {boolean} allowSingleFacets forces single facet
 * @param {boolean} isRedirectFromLeasePage forces single facet
 * @param {number} radius
 * @param {boolean} shouldPersistExpandedRadius
 * @param {boolean} isMultiMM
 * @param {object} facets
 * @param {boolean} isSHOP1857Enabled
 * @returns {Object} new filter
 */
export function getFilter({
  type,
  facet,
  isChecked,
  currentFilter,
  allowSingleFacets = false,
  isRedirectFromLeasePage = false,
  radius,
  shouldPersistExpandedRadius = false,
  isMultiMM = false,
  facets = [],
  isSHOP1857Enabled,
}) {
  let newFilter = {};

  if (!(type && facet)) {
    newFilter[API.RADIUS] = radius; // keep default radius for the user’s location when clear all filters
    EXCLUDE_FROM_RESET_FACETS.forEach(key => {
      if (currentFilter[key]) {
        newFilter = { ...newFilter, [key]: currentFilter[key] };
      }
    });
    // clear filter
    return newFilter;
  }

  // reset filters except for defined
  if (!isMultiMM && EXCLUDE_RESET_FACETS_BY[type]) {
    EXCLUDE_RESET_FACETS_BY[type].forEach(key => {
      // Get current filter values
      const currentFilterValue = currentFilter[key];
      // If facet is presented in exclusion get it
      const excludeFromFilterValue = EXCLUDE_VALUES_FROM_FILTER[key];

      // Check that current value and exclusion value are exist
      if (currentFilterValue && excludeFromFilterValue) {
        // Check that exclusion contains exception, also facet should be verified that it's checked
        // because it can 'cause a problem, for example, when we unselect model from 'Filter By' section,
        // radius should be removed, but without verify that `isChecked` true it'll be passed to request params.
        const isExclSelected = excludeFromFilterValue.exceptions.includes(type) && isChecked;
        const isValuesEqual = excludeFromFilterValue.value === currentFilterValue[0];
        newFilter = !isExclSelected && isValuesEqual ? { ...newFilter } : { ...newFilter, [key]: currentFilterValue };
      } else if (currentFilterValue) {
        newFilter = { ...newFilter, [key]: currentFilterValue };
      }
    });
  } else {
    newFilter = { ...currentFilter };
  }

  if (shouldPersistExpandedRadius) {
    newFilter[API.RADIUS] = radius;

    if (radius.toString() === NATIONWIDE_VALUE) {
      newFilter[API.SORT_BY] = [expandedRadiusDueToLowResultsSort];
    }
  }

  const resetFacetsBy = isMultiMM ? RESET_FACETS_BY_FOR_MULTI_MM : RESET_FACETS_BY;
  // reset filters by facet type
  if (resetFacetsBy[type]) {
    resetFacetsBy[type].forEach(key => unset(newFilter, key));
  }

  // should be always reset facets when select any filter, e.g pageNum but if selected facet is not in exceptions
  if (!RESET_FACETS.exceptions.includes(type)) {
    RESET_FACETS.rules.forEach(key => unset(newFilter, key));
  }

  const singleFacets = isMultiMM ? SINGLE_FACETS_FOR_MULTI_MM : SINGLE_FACETS;
  // filters having only one value
  if (singleFacets.includes(type) || allowSingleFacets) {
    unset(newFilter, type);
  }

  // merge selected facets with new facet
  const selectedValues = newFilter[type] || [];
  const facetValue = facet.value || facet.name;
  const newValue = COMBINED_FACETS.includes(type) ? facetValue.split(',') : [facetValue];
  let currentFacet = isChecked ? [...selectedValues, ...newValue] : without(selectedValues, ...newValue);
  const isMMUpdate = isMultiMM && [API.MAKE, API.MODEL].includes(type);
  let modelFacet;
  const removeMakeRelatedModels = type === API.MAKE && !isChecked;
  const modelFilterValues = currentFilter[API.MODEL] ? [...currentFilter[API.MODEL]] : [];

  if (isMMUpdate) {
    const currentFilterValues = currentFilter[type] ? [...currentFilter[type]] : [];

    if (isChecked) {
      if (!currentFilterValues.includes(facetValue)) {
        // add selected value if it is not in the list yet
        currentFilterValues.push(facetValue);
      }

      currentFacet = [...compact(currentFilterValues)];
    } else {
      currentFacet = [...without(currentFilterValues, facetValue)];
    }

    // Remove model value either when selected make has changed or make/model pair is to be removed
    if (facet.modelToRemove) {
      modelFacet = [...without(modelFilterValues, facet.modelToRemove)];
    } else if (removeMakeRelatedModels) {
      // remove all models of make removed by active filters in drawer on mobile
      modelFacet = [...modelFilterValues.filter(modelValue => modelValue && !modelValue.includes(`${facetValue}|`))];
    }
  }

  const updatedFacets = currentFacet.length ? { ...newFilter, [type]: currentFacet } : omit(newFilter, type);

  if (isMMUpdate && (facet.modelToRemove || removeMakeRelatedModels)) {
    updatedFacets[API.MODEL] = modelFacet;
  }

  // when model is removed then remove also its trims
  const isMMRemove = isMMUpdate && !isChecked;
  if (isMMRemove && updatedFacets[API.TRIM] && updatedFacets[API.TRIM].length) {
    let modelsToRemove = [];

    if (type === API.MODEL) {
      modelsToRemove = [facet.value];
    } else {
      modelsToRemove = facet.modelToRemove
        ? [facet.modelToRemove]
        : modelFilterValues.filter(modelValue => modelValue && modelValue.includes(`${facetValue}|`));
    }
    updatedFacets[API.TRIM] = remainingTrimsAfterRemove({
      modelsToRemove: compact(modelsToRemove),
      facets,
      updatedFacets,
      isSHOP1857Enabled,
    });
  }

  // after redirect from lease page we should set lease payment type
  // if it is still for NEW vehicles and loan price/display price filter is not selected
  if (isRedirectFromLeasePage && isNewInventoryTypeSelected(updatedFacets) && type !== API.PAYMENT_TYPE) {
    if (updatedFacets[API.LOAN_PAYMENT] || updatedFacets[API.DISPLAY_PRICE]) {
      unset(updatedFacets, [API.PAYMENT_TYPE]);
    } else {
      set(updatedFacets, [API.PAYMENT_TYPE], [SEO.LEASE_PAYMENT]);
    }
  }

  // should exclude facet from selected facets when current selected facet has invalid
  // value for facet that should be excluded
  const excludeFacetByCheckingValue = EXCLUDE_FACET_BY_CHECKING_VALUE[type];
  if (excludeFacetByCheckingValue) {
    const { facetNameToExclude, checkCallback = isUsedInventoryTypeSelected } = excludeFacetByCheckingValue;
    // check that facet that should be excluded is presented in selected facets
    const facetToExclude = updatedFacets[facetNameToExclude];
    // call check callback to know can we exclude facet or we should keep it as is
    const keepFacet = checkCallback(updatedFacets);

    if (!!facetToExclude && !keepFacet) {
      const { valuesToRemove } = excludeFacetByCheckingValue;
      // check that values to remove are presented in facet that should be excluded
      const shouldExclude = facetToExclude.some(value => valuesToRemove.includes(value));

      if (shouldExclude) {
        unset(updatedFacets, facetNameToExclude);
      }
    }
  }

  return updatedFacets;
}

/**
 * Checks whether facet value is empty
 * @param {String} value
 * @returns {boolean}
 */
function isEmptyFilter(value) {
  return isUndefined(value) || EMPTY_FILTER.includes(value);
}

/**
 * Cleans up value if value is set to 'Any' (*, asterisk)
 * @param {string} value
 * @returns {string|null}
 */
function cleanRangeValue(value) {
  return value === EMPTY_VALUE ? null : parseInt(value, 10);
}

/**
 * Transforms single number to a range if applicable
 * @param {string} value
 * @returns {string}
 */
function valueToRange(value) {
  return NUMBER_REGEX.test(value) ? `${value}-${value}` : value;
}

/**
 * Transforms passed value to lowerCase if it's a string. Otherwise - returns the initial value.
 * @param {string} value
 * @returns {string}
 */
function toLowerCaseValue(value) {
  return value && typeof value === 'string' ? value.toLowerCase() : value;
}

/**
 * Parses string If value represents min-max range
 * @param value
 * @returns {Object|null}
 */
export function parseRange(value) {
  if (isEmptyFilter(value) || !RANGE_REGEX.test(value)) {
    return null;
  }

  const [, rawMin, rawMax] = value.match(RANGE_REGEX);

  return {
    min: cleanRangeValue(rawMin),
    max: cleanRangeValue(rawMax),
  };
}

/**
 * Gets selected values and all facets and unite them into resulted facets.
 * It fixes issue when request hasn't yet come and user doesn't see any changes with
 * interactive elements (e.g. checkbox selected state).
 * @returns {Array}
 */
export function getResultedFacets(facets, selectedFacets) {
  const facetsOrderedKeys = facets.map(facet => facet.type);
  const mergedFacets = unionWith(selectedFacets, facets, (first, second) => first.type === second.type);
  return sortBy(mergedFacets, facet => facetsOrderedKeys.indexOf(facet.type));
}

/**
 * Returns information about selected facets from flat url properties resolved on server
 * @param data
 */
export function getFlatUrlFilters(data) {
  const dataObj = data || {};
  const vehicle = dataObj.vehicle;
  const inventory = (dataObj.attributes && dataObj.attributes.inventory) || {};

  const {
    inventoryType,
    bodyType,
    engineType,
    maxPrice,
    colorName,
    minPrice,
    paymentType,
    maxLeasePayment,
    truckCabSize,
  } = inventory;
  return omitBy(
    {
      [SEO.MAKE]: get(vehicle, 'make.slug'),
      [SEO.MODEL]: get(vehicle, 'model.slug'),
      [SEO.TRIM]: get(inventory, 'trim'),
      [SEO.INVENTORY_TYPE]: inventoryType,
      [SEO.BODY_TYPE]: bodyType,
      [SEO.ENGINE_TYPE]: engineType,
      [SEO.DISPLAY_PRICE]: `${defaultTo(minPrice, '*')}-${defaultTo(maxPrice, '*')}`,
      [SEO.EXTERIOR_COLOR]: colorName,
      [SEO.YEAR]: get(vehicle, 'modelYear.year'),
      [SEO.PAYMENT_TYPE]: paymentType,
      [SEO.LEASE_PAYMENT]: `*-${defaultTo(maxLeasePayment, '*')}`,
      [SEO.TRUCK_CAB_SIZE]: truckCabSize,
    },
    isEmptyFilter
  );
}

function getParamValue(param) {
  return !!param && param.includes('|') ? param.split('|')[1] : param;
}

/**
 * Return multi param
 * @param {String} rightPartStr
 * @param {Array} leftPartArr
 * @returns {*}
 */
function getMultiParam({ leftPartArr, rightPartStr }) {
  return rightPartStr
    .split(',')
    .map(paramName =>
      leftPartArr && leftPartArr.length === 1 && leftPartArr[0] && paramName && !paramName.includes('|')
        ? `${getParamValue(leftPartArr[0])}|${makeNiceName(paramName)}`
        : makeNiceName(paramName)
    );
}

/**
 * Transforms filters from location to the format, which filter components support
 * @param {Object} data Query parameters
 * @returns {Object} an object without empty filters and with the necessary transformations
 */
export function prepareLocationFilters(data) {
  const make = get(data, SEO.MAKE, '')
    .split(',')
    .map(makeName => makeNiceName(makeName));
  const model = getMultiParam({ leftPartArr: make, rightPartStr: get(data, SEO.MODEL, '') });
  const trim = getMultiParam({ leftPartArr: model, rightPartStr: get(data, SEO.TRIM, '') });
  const type = get(data, SEO.BODY_TYPE);
  const year = valueToRange(get(data, SEO.YEAR));
  const inventorytype = toLowerCaseValue(get(data, SEO.INVENTORY_TYPE));

  return omitBy(
    {
      ...data,
      make,
      model,
      year,
      type,
      inventorytype,
      trim,
    },
    isEmptyFilter
  );
}

/**
 * Returns page filter from browser location
 * @param {Object} query Location query string
 * @param {string} urlContext urlContext
 * @returns {Object}
 */
export function getFilterFromLocation(query, urlContext) {
  const flatUrlFilters = getFlatUrlFilters(urlContext);
  const combinedFilters = { ...query, ...flatUrlFilters };

  return flow(
    prepareLocationFilters,
    defineSelectedFilters
  )(combinedFilters);
}

/**
 * Returns information for `vehicle` segment from flat url properties resolved on server (filter)
 * @param {Object} data filter
 * @returns {object} vinData
 */
export function getVehicleDataFromFilter(data) {
  const vinData = {};

  const make = get(data, `${API.MAKE}[0]`);
  const model = get(data, `${API.MODEL}[0]`);
  const inventoryType = get(data, API.INVENTORY_TYPE);
  const bodyType = get(data, API.BODY_TYPE);
  const displayPrice = parseRange(get(data, `${API.DISPLAY_PRICE}[0]`));
  const maxPrice = get(displayPrice, 'max');
  const interiorColor = get(data, `${API.INTERIOR_COLOR}[0]`);
  const exteriorColor = get(data, `${API.EXTERIOR_COLOR}[0]`);
  const year = parseRange(get(data, `${API.YEAR}[0]`));
  const modelYear = year && year.max && (year.min === year.max || year.min < year.max) ? year.max : null;
  const trim = get(data, `${API.TRIM}[0]`);

  if (make) set(vinData, 'vehicleInfo.styleInfo.make', make);
  if (model) set(vinData, 'vehicleInfo.styleInfo.model', model);
  if (inventoryType) set(vinData, 'type', inventoryType.join());
  if (bodyType) set(vinData, 'vehicleInfo.styleInfo.bodyType', bodyType.join().toLowerCase());
  if (maxPrice) set(vinData, 'vehicleInfo.displayPrice', maxPrice);
  if (interiorColor) set(vinData, 'vehicleInfo.vehicleColors.interior.genericName', interiorColor);
  if (exteriorColor) set(vinData, 'vehicleInfo.vehicleColors.exterior.genericName', exteriorColor);
  if (modelYear) set(vinData, 'vehicleInfo.styleInfo.year', modelYear);
  if (trim) set(vinData, 'vehicleInfo.styleInfo.trim', trim);

  return vinData;
}

export function getVehicleDataFromFilterInventories(data, inventories = []) {
  const vinData = getVehicleDataFromFilter(data);

  const make = get(vinData, 'vehicleInfo.styleInfo.make');
  const model = get(vinData, 'vehicleInfo.styleInfo.model');

  if (make || model) {
    const firstInventory = inventories[0];
    const interiorColor = get(firstInventory, 'vehicleInfo.vehicleColors.interior.genericName');
    const exteriorColor = get(firstInventory, 'vehicleInfo.vehicleColors.exterior.genericName');
    set(vinData, 'vehicleInfo.vehicleColors.interior.genericName', interiorColor);
    set(vinData, 'vehicleInfo.vehicleColors.exterior.genericName', exteriorColor);
  }

  return vinData;
}

/**
 * Returns the first found facet of SRP facets
 * @param {Array} facets
 * @param {String} selectedType
 * @returns {Object} InventoryEntities.Facet
 */
export function getFacetByType(facets = [], selectedType = null) {
  return facets.find(({ type }) => type === selectedType);
}

/**
 * The helper takes out a field value.
 * @param {Object} selectedFacet
 * @param {String} field
 * @returns {String} - first field value
 */
export const getFirstFacetFieldValue = (selectedFacet, field) => get(selectedFacet, 'values[0]', {})[field];

/**
 * The function serves for getSelectedFacetField.
 * @param {Object} selectedFacet
 * Structure:
 * {
 *   type: 'Some type',
 *   values: [{...}, {..., selected: true }],
 * }
 * @returns {Object} selectedValue
 */
export const getSelectedFacetValue = selectedFacet =>
  find(({ selected }) => selected)(get(selectedFacet, 'values', []));

/**
 * The function takes a name of selected facet field and returns a predefined function.
 * @param field
 * @return {function(*=): *}
 */
export const getSelectedFacetField = field =>
  /**
   * The function takes the selected facet and returns field's value.
   * @param selectedFacet
   * @return {any}
   */
  selectedFacet => get(getSelectedFacetValue(selectedFacet), field, getFirstFacetFieldValue(selectedFacet, field));

/**
 * The function composes getFacetByType with getSelectedFacetField and returns a facet field value.
 * @param facets
 * @param type
 * @param field
 * @return {any}
 */
export const getFacetField = (facets, type, field) =>
  flow([getFacetByType, getSelectedFacetField(field)])(facets, type);

export const getSelectedFacetValues = selectedFacet =>
  filter(({ selected }) => selected)(get(selectedFacet, 'values', []));

export const getSelectedFacetValuesByField = field => selectedFacet =>
  getSelectedFacetValues(selectedFacet).map(facetValue => get(facetValue, field, ''));

export function isUrlPatternEnabled({ initialUrlPattern, urlContext }) {
  const urlPattern = get(urlContext, 'urlPattern');
  return (
    isUsedYMMCityStateSRP(urlPattern) ||
    isSrpOnUsedCorePage(urlContext) ||
    !!initialUrlPattern ||
    isCoreSrpMMYS(urlPattern)
  );
}
