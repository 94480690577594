export class AdsPositionManager {
  static instance;

  static getInstance() {
    if (AdsPositionManager.instance) {
      return AdsPositionManager.instance;
    }
    AdsPositionManager.instance = new AdsPositionManager();
    return AdsPositionManager.instance;
  }

  constructor() {
    this.data = new Map();
  }

  getPosition(adName, nativeStyle, startingPosition) {
    const key = `${adName}-${nativeStyle}`;
    if (!this.data.has(key)) {
      this.data.set(key, parseInt(startingPosition, 10));
    } else {
      this.data.set(key, this.data.get(key) + 1);
    }
    return this.data.get(key);
  }
}
