import { get } from 'lodash';
import { getMappedStateForUser } from 'site-modules/shared/utils/publication-states';

export const parseVehicleForAction = vehicleStyle => {
  const mappedPublicationState = getMappedStateForUser(vehicleStyle.publicationState);
  const submodelName = get(vehicleStyle, 'submodels.name', '');
  const submodelNiceName = get(vehicleStyle, 'submodels.slug', '');

  return {
    make: {
      id: get(vehicleStyle, 'make.id'),
      name: vehicleStyle.makeName,
      niceName: vehicleStyle.makeSlug,
      adTargetId: get(vehicleStyle, 'make.adTargetId'),
    },
    model: {
      modelLinkCode: get(vehicleStyle, 'model.modelLinkCode'),
      name: vehicleStyle.modelName,
      niceName: vehicleStyle.modelSlug,
      adTargetId: get(vehicleStyle, 'model.adTargetId'),
    },
    modelYear: {
      id: get(vehicleStyle, 'modelYearId'),
      year: vehicleStyle.year,
    },
    subModel: {
      name: submodelName,
      niceName: submodelNiceName,
      adTargetId: get(vehicleStyle, 'submodels.adTargetId'),
    },
    publicationState: mappedPublicationState ? mappedPublicationState.toLowerCase() : 'new',
    style: { id: vehicleStyle.id },
  };
};
