import { PAGE_URL } from 'site-modules/shared/constants/page-urls';
import { getHomeBreadcrumb } from 'site-modules/shared/components/breadcrumbs/helper';
import { getBreadcrumbName } from 'site-modules/shared/utils/upper-funnel/type-mapping';
import { getTypeUrl } from 'site-modules/shared/utils/type-link-constructor';

export const INCENTIVES_BREADCRUMBS = [{ title: 'Home', href: PAGE_URL.HOME }, { title: 'Incentives and Rebates' }];
export const INCENTIVES_ABOUT_BREADCRUMBS = [
  { title: 'Home', href: PAGE_URL.HOME },
  { title: 'Incentives and Rebates', href: PAGE_URL.CAR_INCENTIVES },
  { title: 'About Incentives and Rebates', href: `${PAGE_URL.CAR_INCENTIVES}about.html` },
];

export const ABOUT_INCENTIVES_CONTENT_PATH = '/incentives-about-content';

export const INCENTIVE_CATEGORY = {
  REBATES: 'REBATES',
  FINANCING: 'FINANCING',
  LEASING: 'LEASING',
  OTHER: 'OTHER',
};

export const INCENTIVE_TYPES = {
  FINANCING: 'financing',
  LEASING: 'leasing',
  OTHER: 'other',
  REBATES: 'rebates',
};

export const STYLES_INCENTIVES_TITLES = {
  [INCENTIVE_TYPES.REBATES]: 'Cash Offers',
  [INCENTIVE_TYPES.FINANCING]: 'Financing',
  [INCENTIVE_TYPES.LEASING]: 'Leasing',
  [INCENTIVE_TYPES.OTHER]: 'Other',
};

export const STYLES_INCENTIVES_ORDER = [
  INCENTIVE_TYPES.REBATES,
  INCENTIVE_TYPES.FINANCING,
  INCENTIVE_TYPES.LEASING,
  INCENTIVE_TYPES.OTHER,
];

export const MAKE_INCENTIVES_TITLES = {
  [INCENTIVE_TYPES.REBATES]: '$ Offer',
  [INCENTIVE_TYPES.FINANCING]: 'Financing',
  [INCENTIVE_TYPES.LEASING]: 'Leasing',
  [INCENTIVE_TYPES.OTHER]: 'Other',
};

export const MAKE_INCENTIVES_ORDER = [
  INCENTIVE_TYPES.REBATES,
  INCENTIVE_TYPES.FINANCING,
  INCENTIVE_TYPES.LEASING,
  INCENTIVE_TYPES.OTHER,
];

export const CUSTOMER_BONUS_CASH = 'CUSTOMER_BONUS_CASH';
export const CUSTOMER_CASH = 'CUSTOMER_CASH';
export const CUSTOMER_CASH_AND_APR = 'CUSTOMER_CASH_AND_APR';
export const CUSTOMER_CASH_OR_APR = 'CUSTOMER_CASH_OR_APR';
export const PERCENTAGE = 'PERCENTAGE';

export const INCENTIVE_HEAD_TITLES = {
  [CUSTOMER_BONUS_CASH]: ['Bonus $ Offer', 'Start', 'End'],
  [CUSTOMER_CASH]: ['Customer $ Offer', 'Start', 'End'],
  [CUSTOMER_CASH_AND_APR]: ['Customer $ Offer', 'Start', 'End'],
  [CUSTOMER_CASH_OR_APR]: ['Customer $ Offer', 'Start', 'End'],
  [PERCENTAGE]: ['Customer $ Offer', 'Start', 'End'],
  [INCENTIVE_TYPES.FINANCING]: ['Special APR', 'Month term', 'Start', 'End'],
  [INCENTIVE_TYPES.LEASING]: ['Leasing Bonus Cash', 'Start', 'End'],
  [INCENTIVE_TYPES.OTHER]: ['Tax Rebate', 'Start', 'End'],
};

export const INCENTIVE_OTHER_HEAD_TITLES = {
  NON_CASH_TO_CUSTOMER: 'Tax Rebate',
  OTHER_OFFERS: 'Offer',
};

export const INCENTIVE_NAMES_MAP = {
  [INCENTIVE_TYPES.REBATES]: 'rebatesIncentive',
  [INCENTIVE_TYPES.FINANCING]: 'financingIncentive',
  [INCENTIVE_TYPES.LEASING]: 'leasingIncentive',
  [INCENTIVE_TYPES.OTHER]: 'otherIncentive',
};

export const INCENTIVE_TYPE_CREATIVE_ID = {
  [INCENTIVE_TYPES.REBATES]: 'cash_offers_incentives',
  [INCENTIVE_TYPES.FINANCING]: 'financing_incentives',
  [INCENTIVE_TYPES.LEASING]: 'leasing_incentives',
  [INCENTIVE_TYPES.OTHER]: 'other_incentives',
};

export const ELECTRIC_CAR_INCENTIVES_BREADCRUMBS = [
  getHomeBreadcrumb(),
  { title: getBreadcrumbName('electric'), href: getTypeUrl('electric') },
  { title: 'Incentives' },
];
export const ELECTRIC_CAR_STATE_INCENTIVES_BREADCRUMBS = [
  ELECTRIC_CAR_INCENTIVES_BREADCRUMBS[0],
  ELECTRIC_CAR_INCENTIVES_BREADCRUMBS[1],
  { title: 'Incentives', href: PAGE_URL.ELECTRIC_CAR_INCENTIVES },
];

export const INCENTIVE_TRANSACTION_TYPES = {
  CASH: 'CASH',
  LEASE: 'LEASE',
};

export const PROGRAM_TYPES = {
  EV_REBATE: 'EV Rebate',
  EV_CHARGING: 'EV Charging',
  TAX_CREDIT: 'Tax Credit',
  UTILITY_REBATE: 'Utility Rebate',
  MUNICIPALITY_REBATE: 'Municipality Rebate',
  CHARGER_INSTALLATION: 'Charger Installation',
  STATE_REBATE: 'State Rebate',
  CHARGER_REBATE: 'Charger Rebate',
};

export const CONTENT_TYPES = {
  CUSTOMER_APR: 'CUSTOMER_APR',
  DEALER_CASH: 'DEALER_CASH',
};

const TRANSACTION_TYPES = { CASH: 'CASH', FINANCE: 'FINANCE', LEASE: 'LEASE' };

export const TRANSACTION_TYPES_MAP = {
  [TRANSACTION_TYPES.CASH]: 'Cash Purchase',
  [TRANSACTION_TYPES.FINANCE]: 'Finance',
  [TRANSACTION_TYPES.LEASE]: 'Lease',
};
