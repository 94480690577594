import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getIntersectionObserver } from 'client/utils/intersection-observer';
import { FeatureFlag } from 'site-modules/shared/components/feature-flag/feature-flag';

class RenderWhenViewableUI extends Component {
  static propTypes = {
    placeholder: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    verticalOffset: PropTypes.string.isRequired,
    onRender: PropTypes.func,
  };
  static defaultProps = {
    placeholder: null,
    onRender: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      shouldLoad: false,
    };
    const IntersectionObserver = getIntersectionObserver();
    if (IntersectionObserver) {
      this.observer = new IntersectionObserver(
        changes => {
          changes.forEach(change => {
            if (change.isIntersecting) {
              this.setState({ shouldLoad: true });
              this.observer.unobserve(change.target);
              props.onRender();
            }
          });
        },
        /**
         * rootMargin - a string that can have values similar to the CSS margin property,
         *              e.g. "10px 20px 30px 40px" (top, right, bottom, left).
         *
         * This set of values serves to grow or shrink each side of the root element's bounding box before computing
         * intersections.
         *
         * Since we are not specifying a "root" element in our arguments to the IntersectionObserver it will default
         * to the browser viewport.
         *
         * Example values (in terms of rendering for our context):
         * - '0px'     - render when the element enters the viewport
         * - '50% 0%'  - render 1/2 a "screen" before the element enters the viewport
         * - '100% 0%' - render 1 "screen" before the element enters the viewport
         * - '200% 0%' - render 2 "screens" before the element enters the viewport
         * - '20px 0px'  - render 20px  before the element enters the viewport
         * - '100px 0px' - render 100px before the element enters the viewport
         *
         * For more details:
         *   @see https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#Intersection_observer_options
         */
        { rootMargin: `${this.props.verticalOffset} 0px` }
      );
    }
  }

  componentDidMount() {
    if (this.observer && this.placeholderRef) {
      this.observer.observe(this.placeholderRef);
    }
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  render() {
    return this.state.shouldLoad ? (
      this.props.children
    ) : (
      <div
        data-unit-cmp="RenderWhenViewable placeholder container"
        ref={elem => {
          this.placeholderRef = elem;
        }}
      >
        {this.props.placeholder}
      </div>
    );
  }
}

export function RenderWhenViewable(props) {
  return (
    <FeatureFlag name={['allClientRender', 'renderWhenViewableComponent']}>
      <RenderWhenViewableUI {...props} />
    </FeatureFlag>
  );
}

RenderWhenViewable.propTypes = {
  placeholder: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  /**
   * verticalOffset - A string ending in 'px' or '%' that determines how much vertical space directly before the
   *                  placeholder should be visible before the children are rendered.
   *
   * Example values:
   * - '0px'  - render when the element enters the viewport (default)
   * - '50%'  - render 1/2 a "screen" before the element enters the viewport
   * - '100%' - render 1 "screen" before the element enters the viewport
   * - '200%' - render 2 "screens" before the element enters the viewport
   * - '20px'  - render 20px  before the element enters the viewport
   * - '100px' - render 100px before the element enters the viewport
   */
  verticalOffset: (props, propName, componentName) => {
    if (!(props[propName] && (props[propName].endsWith('px') || props[propName].endsWith('%')))) {
      return new Error(
        `Invalid prop '${propName}' supplied to '${componentName}'. Must be a string ending in 'px' or '%'`
      );
    }
    return null; // validation passed
  },
  onRender: PropTypes.func,
};

RenderWhenViewable.defaultProps = {
  placeholder: null,
  verticalOffset: '0px', // begin rendering the children immediately when they enter the viewport
  onRender: () => {},
};
