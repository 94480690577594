import { makeNiceName } from 'site-modules/shared/utils/nice-name';
import { vdpLinkBuilder } from 'site-modules/shared/utils/vdp-link-constructor';

/**
 * Generates a link to VDP for an inventory.
 *
 * @param {object} inventory
 * @param {boolean} isNational
 * @param {boolean} skipLeasePaymentType
 * @param {string|number} [radius] - optional
 * @param {boolean} [buyonline] - optional
 */
export function getVdpUrl(inventory, isNational, skipLeasePaymentType = true, { radius, buyonline, deliveryFee } = {}) {
  const vehicleInfo = inventory.vehicleInfo;
  const vin = inventory.vin;

  if (vehicleInfo && vin) {
    const vehicle = {
      make: makeNiceName(vehicleInfo.styleInfo.make),
      model: makeNiceName(vehicleInfo.styleInfo.model),
      year: vehicleInfo.styleInfo.year,
      vin,
    };
    const queryParams = {
      national: isNational || null,
      paymenttype: skipLeasePaymentType ? null : 'lease',
      radius: radius || null,
      deliveryFee: deliveryFee || null,
    };

    return vdpLinkBuilder(vehicle, queryParams, buyonline);
  }

  return null;
}
