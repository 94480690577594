import { makeNiceName } from 'site-modules/shared/utils/nice-name';
import { get, merge, maxBy } from 'lodash';
import {
  MAKE_MODEL_SRP,
  MAKE_SRP,
  OPEN_SRP,
  MAKE_SRP_AD_TARGETING,
  MULTI_SEARCH_SRP,
  MULTI_SEARCH_SRP_AD_TARGETING,
  BT_SRP,
  BT_SRP_AD_TARGETING,
} from 'site-modules/shared/constants/inventory/srp-page-types';
import { getVehicleFromInventoryAttrs } from 'client/actions/vehicle';
import { getFacetByType, getVehicleDataFromFilterInventories } from 'site-modules/shared/utils/inventory/search-filter';
import { MODEL } from 'site-modules/shared/constants/allowed-inventory-request-params';

/**
 * @param {Object} vehicle - Obj with make and model data
 * @param {Object} pageFilters - Obj with filters
 * @return {String} - Srp Type
 */
export const getSrpType = (vehicle = null, pageFilters = null) => {
  const isMultiSearch = get(pageFilters, 'make.length', 0) > 1;
  if (isMultiSearch) {
    return MULTI_SEARCH_SRP;
  }

  const { make = {}, model = {} } = vehicle || {};

  if (make.niceName && model.niceName) {
    return MAKE_MODEL_SRP;
  }
  if (make.niceName) {
    return MAKE_SRP;
  }

  if (pageFilters !== null && !get(pageFilters, 'rooftopId')) {
    return BT_SRP;
  }

  return OPEN_SRP;
};

/**
 * @param {String} srpType
 * @return {Object} Data with for customTargeting for AdUnit
 */
export const getSrpAdTargeting = srpType => {
  if (srpType === MAKE_SRP) {
    return MAKE_SRP_AD_TARGETING;
  } else if (srpType === MULTI_SEARCH_SRP) {
    return MULTI_SEARCH_SRP_AD_TARGETING;
  } else if (srpType === BT_SRP) {
    return BT_SRP_AD_TARGETING;
  }
  return {};
};

export const getMakeModel = (modelGroups, inventory) => {
  if (inventory.length > 0) {
    const firstVehicle = get(inventory, '0.vehicleInfo.styleInfo', {});
    return {
      make: {
        niceName: makeNiceName(firstVehicle.make),
      },
      model: {
        niceName: makeNiceName(firstVehicle.model),
      },
    };
  }

  const make = Object.keys(modelGroups)[0];
  const modelsList = modelGroups[make];
  // find more selling
  const popularModel = maxBy(modelsList, 'count');
  const model = get(popularModel, 'name', get(modelsList, '[0].name', ''));

  return {
    make: {
      niceName: makeNiceName(make),
    },

    model: {
      niceName: makeNiceName(model),
    },
  };
};

/**
 * @param {Object} vehicle - Obj with vehicle data: make, model, year, etc
 * @param {Object} modelGroups - List of all models for specific make
 * @param {Array} inventory - List of availible vehicles for selected condition
 * @param {String} srpType
 * @return {Object || null} - Obj with currentVehicle data for AdUnit on OpenSrp and MakeSrp pages or null for not MAKE_SRP
 */
export const getVehicleForAdConfiguration = (vehicle, modelGroups, inventory = [], srpType) => {
  if (MAKE_SRP === srpType) {
    const model = getMakeModel(modelGroups, inventory).model;
    return { ...vehicle, model };
  }

  if (MULTI_SEARCH_SRP === srpType) {
    return { ...vehicle, ...getMakeModel(modelGroups, inventory) };
  }

  if (BT_SRP === srpType) {
    return { ...vehicle, bt: true };
  }

  return null;
};

export function getVehicleFromSrpInventoryData(inventoryData, pageFilters) {
  const inventoryResults = get(inventoryData, 'inventories.results', []);
  // get vehicle targeting for ads
  const vinData = getVehicleDataFromFilterInventories(pageFilters, inventoryResults);
  const vehicleMetadata = get(inventoryData, 'attributes.vehicle', {});
  let vehiclePageData = getVehicleFromInventoryAttrs(vinData, vehicleMetadata);

  const inventoryFacets = get(inventoryData, 'facets', []);
  const modelGroups = get(getFacetByType(inventoryFacets, MODEL), 'groups', {});
  const srpType = getSrpType(vehiclePageData, pageFilters);

  const vehicleAdData = getVehicleForAdConfiguration(vehiclePageData, modelGroups, inventoryResults, srpType);

  if (srpType === MULTI_SEARCH_SRP) {
    vehiclePageData = merge({}, vehiclePageData, vehicleAdData);
  }
  return { vehiclePageData, vehicleAdData };
}

export function getTargetingData(inventoryData, selectedFilters) {
  const navigationBetweenPages = get(selectedFilters, 'pageNum.[0]');
  const { vehiclePageData, vehicleAdData: currentVehicle } = getVehicleFromSrpInventoryData(
    inventoryData,
    selectedFilters
  );
  const srpType = getSrpType(vehiclePageData, selectedFilters);
  const customTargeting = getSrpAdTargeting(srpType);

  return {
    currentVehicle: currentVehicle || vehiclePageData,
    customTargeting: { ...customTargeting, navigationBetweenPages },
    dependedOnCurrentVehicle: true,
  };
}
