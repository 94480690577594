import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FeatureFlag } from 'site-modules/shared/components/feature-flag/feature-flag';

const SHOW_TIMEOUT = 50;

/**
 * Any ClientSideRender component nested (directly or indirectly) inside of another CSR
 * will initialize with state= { isVisible: true }, vs the topmost one will initialize with
 * isVisible: false on both server and client, and change state to isVisible: true on client.
 * This flow is achieved via the topmost CSR creating the following context variable that is
 * available to it's descendants.
 * Without this logic nested CSRs would end up mounting sequentially in a chain, which could
 * unintentionally delay the init of bottom most node, - this was what was happening for some of
 * our ad units.
 * @type {React.Context<boolean>}
 */
const HasCSRAncestorContext = React.createContext(false);

/**
 A component for configurable skip loading.
 @examples
 ```jsx
 <ClientSideRender>
 <Footer />
 </ClientSideRender>
 ```
 @component ClientSideRender
 @import {ClientSideRender}
 @playground
 ClientSideRender
 ```
 <ClientSideRender skip={true}>
 <Footer />
 </ClientSideRender>
 ```
 @returns {ReactElement} The rendered component
 */

class ClientSideRenderUI extends Component {
  static propTypes = {
    /**
     Children to render when visible
     */
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),

    /**
     Pass in another element to render when skipping server side rendering
     */
    placeholder: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),

    /**
     Sets the className of the default placeholder
     */
    placeholderClassName: PropTypes.string,

    /**
     Sets the style of the default placeholder
     */
    placeholderStyle: PropTypes.shape({}),

    /**
     Sets the timeout or the delay of visible
     */
    showTimeout: PropTypes.number,
  };

  static defaultProps = {
    children: null,
    placeholder: null,
    placeholderClassName: null,
    placeholderStyle: null,
    showTimeout: SHOW_TIMEOUT,
  };

  static contextType = HasCSRAncestorContext;

  static displayName = 'ClientSideRenderUI';

  constructor(props, context) {
    super(props, context);
    const hasCSRAncestor = context;
    this.state = hasCSRAncestor ? { isVisible: true } : { isVisible: false };
  }

  componentDidMount() {
    if (!this.state.isVisible) {
      this.timeout = setTimeout(() => {
        this.setState({ isVisible: true });
      }, this.props.showTimeout);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    this.timeout = undefined;
  }

  isVisible() {
    return this.state.isVisible;
  }

  render() {
    if (this.isVisible()) {
      return <HasCSRAncestorContext.Provider value>{this.props.children}</HasCSRAncestorContext.Provider>;
    }

    const { placeholder, placeholderClassName, placeholderStyle } = this.props;

    if (placeholder) {
      return typeof placeholder === 'function' ? React.createElement(placeholder) : placeholder;
    } else if (placeholderClassName || placeholderStyle) {
      return <div className={placeholderClassName} style={placeholderStyle} />;
    }
    return false;
  }
}

export function ClientSideRender(props) {
  return (
    <FeatureFlag name={['allClientRender', 'clientSideRenderComponent']}>
      <ClientSideRenderUI {...props} />
    </FeatureFlag>
  );
}
