import React, { Suspense, useState, useEffect } from 'react';
import { FeatureFlag } from 'site-modules/shared/components/feature-flag/feature-flag';

/**
 * the default placeholder if one not specified
 * @returns {boolean}
 * @constructor
 */
function Placeholder() {
  return null;
}

/**
 * use this function to lazy-load a component
 * https://gitlab.shared-services.accounts.edmunds.com/edmunds/node-site-venom/wikis/lazy-loading#async-components
 *
 * this is a wrapper function to React.lazy
 * documented at https://react.dev/reference/react/lazy
 * @example
 * my-component.jsx:
 * const MyComponent = function(props) {return <div>hello</div>}
 *
 * my-component-async.jsx:
 * export const MyComponentAsync = lazyLoad({
 *   loader: () => import(/* webpackChunkName: "my-component" * / './my-component'),
 *   componentName: 'MyComponent',
 * })
 *
 * then <MyComponentAsync {...props} /> can be used the same way as <MyComponent {...props} />
 * @returns React Component
 */
export function lazyLoad(config) {
  const Loading = config.loading || Placeholder;

  const CmpLazy = React.lazy(() =>
    config.loader().then(module => ({
      default: module[config.componentName],
    }))
  );

  const ClientCmp = props => {
    const [isSSR, setIsSSR] = useState(true);

    useEffect(() => {
      setIsSSR(false);
    }, []);
    if (isSSR) return <Loading {...props} />;

    return (
      <FeatureFlag name={['allClientRender', 'lazyLoad']}>
        {enabled =>
          enabled ? (
            <Suspense fallback={<Loading {...props} />}>
              <CmpLazy {...props} />
            </Suspense>
          ) : (
            <Loading {...props} />
          )
        }
      </FeatureFlag>
    );
  };

  return props => <ClientCmp {...props} />;
}
