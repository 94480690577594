import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ExperimentUtil as EU } from 'client/utils/experiment/experiment-util';

class MultivariateExperimentContainer extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    recipeName: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    dimensions: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    defaultDimensions: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    isFeatureFlagOn: PropTypes.bool.isRequired,
    children: PropTypes.func.isRequired,
  };

  static defaultProps = {
    dimensions: null,
  };

  componentDidMount() {
    const { name, recipeName } = this.props;
    EU.markRecipeUsed({ campaignName: name, usedRecipeName: recipeName });
  }

  render() {
    const { recipeName, children, isFeatureFlagOn, dimensions, defaultDimensions } = this.props;
    const renderExperiment = recipeName && isFeatureFlagOn && dimensions !== null;

    return <Fragment>{children(renderExperiment ? dimensions : defaultDimensions)}</Fragment>;
  }
}

/**
 * Maps app redux state to the Experiment component properties.
 * Defines if the target feature is enabled or not.
 *
 * @param  {Object} state    App redux state.
 * @param  {Object} ownProps Own component properties.
 * @return {Object}          Mapped properties.
 */
export function mapStateToProps(state, ownProps) {
  const campaignName = ownProps.name;
  const isFeatureFlagOn = !!state.featureFlags[ownProps.featureFlagId];
  const { recipeName, dimensions } = EU.getForcedOrAssignedAxRecipe({ state, campaignName });

  return {
    recipeName,
    dimensions,
    isFeatureFlagOn,
  };
}

export const MultivariateExperiment = connect(mapStateToProps)(MultivariateExperimentContainer);

MultivariateExperiment.propTypes = {
  name: PropTypes.string.isRequired,
  featureFlagId: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  defaultDimensions: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};
