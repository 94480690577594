import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './spinner.scss';

function pixels(value) {
  return `${value}px`;
}

export function Spinner(props) {
  const { size, thickness, color, className } = props;
  return (
    <span
      className={classNames('universal-spinner', `text-${color}`, className)}
      style={{ width: pixels(size), borderWidth: pixels(thickness) }}
    >
      <span className="before" />
    </span>
  );
}

Spinner.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  thickness: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  className: PropTypes.string,
};

Spinner.defaultProps = {
  size: 24,
  thickness: 1,
  color: 'gray-dark',
  className: '',
};
