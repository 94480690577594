import { objectToQueryString } from './string';
import { makeNiceName } from './nice-name';

/**
 * Generates VDP URL based on provided params.
 * Mandatory parameters: { makeSlug, modelSlug, year, vin }
 *
 * @param {{ makeSlug: string, modelSlug: string, year: number|string, vin: string }} vehicle -
 * vehicle data used for building a link
 * @return {string} - complete relative string url to VDP page
 */
export const vdpLinkBuilder = (vehicle = {}, queryParams = {}, buyonline = false) => {
  const { make, model, year, vin } = vehicle;
  if (!make || !model || !year || !vin) {
    throw new ReferenceError('Vehicle make, model, year and VIN should be defined');
  }

  const query = objectToQueryString(queryParams);

  return `/${makeNiceName(make)}/${makeNiceName(model)}/${year}/vin/${vin}/${
    buyonline ? 'car-buying-online/' : ''
  }${query && `?${query}`}`;
};
