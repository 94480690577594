import { capitalize } from 'lodash';
import { CAR_TYPES } from 'site-modules/shared/constants/upper-funnel/car-types';

export const TYPE_ELECTRIC_CAR_LINK_SLUG = 'electric-car';

/**
 * When we can't just add 's' in all cases (but sometimes we can) and need expanded form
 * @type {{}}
 */
export const TYPE_DISPLAY_MAPPING = {
  [CAR_TYPES.LUXURY]: 'Luxury Car',
  [CAR_TYPES.ELECTRIC]: 'Electric Car',
  [CAR_TYPES.SPORTS_CAR]: 'Sports Car',
  [CAR_TYPES.HYBRID]: 'Hybrid Car',
  [CAR_TYPES.DIESEL]: 'Diesel Car',
  [CAR_TYPES.SUV]: 'SUV',
  [CAR_TYPES.EXOTIC]: 'Exotic Car',
  [CAR_TYPES.ECONOMY]: 'Economy Car',
  [CAR_TYPES.SUBCOMPACT]: 'Subcompact Car',
  [CAR_TYPES.MIDSIZE]: 'Mid-size Car',
  [CAR_TYPES.MUSCLE]: 'Muscle Car',
  [CAR_TYPES.COMPACT]: 'Compact Car',
  ev: 'EV',
  awd: 'AWD',
};

export const TYPE_SINGULAR_LOWERCASE_EXCEPTIONS = {
  [CAR_TYPES.ELECTRIC]: 'electric car',
  [CAR_TYPES.SUV]: 'SUV',
};

/**
 * These are the exceptions to when we need a lowercase display name
 * @type {{}}
 */
export const TYPE_TITLE_LOWERCASE_EXCEPTIONS_MAPPING = {
  [CAR_TYPES.SUV]: 'SUV',
  [CAR_TYPES.ELECTRIC]: 'electric vehicle',
};

/**
 * Map for breadcrumbs
 * @type {{}}
 */
export const TYPE_BREADCRUMB_MAPPING = {
  [CAR_TYPES.SPORTS_CAR]: 'Sports Cars',
};

/**
 * Helper function for user friendly car body type name
 * E.g. Luxury -> Luxury Car, hatchback -> hatchback
 *
 * @param {string} typeSlug
 * @return {string}
 */
export function getUserFriendlyTypeName(typeSlug) {
  if (!typeSlug || typeof typeSlug !== 'string') {
    throw new TypeError('Type name should be defined and should be string');
  }
  const typeFromMap = TYPE_DISPLAY_MAPPING[typeSlug];
  return typeFromMap || capitalize(typeSlug);
}

/**
 * Helper function for transformation undesirable slugs
 * E.g. electric-car -> electric, electric -> electric, suv -> suv
 *
 * @param {string} slug
 * @return {string}
 */
export function getHandySlug(slug) {
  return slug === TYPE_ELECTRIC_CAR_LINK_SLUG ? CAR_TYPES.ELECTRIC : slug;
}

export function getUrlSlugByName(name) {
  const slug = name.toLowerCase();

  return slug === CAR_TYPES.ELECTRIC ? TYPE_ELECTRIC_CAR_LINK_SLUG : slug;
}

/**
 * Map type slugs to correct breadcrumb names
 *
 * @param {string} slug
 * @returns {string}
 */
export function getBreadcrumbName(slug) {
  return TYPE_BREADCRUMB_MAPPING[slug] || getUserFriendlyTypeName(slug);
}
