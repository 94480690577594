export const OPEN_SRP = 'open-srp';
export const MAKE_SRP = 'make-srp';
export const MAKE_MODEL_SRP = 'make-model-srp';
export const USED_SRP = 'used-srp';
export const MULTI_SEARCH_SRP = 'multi-search-srp';
export const BT_SRP = 'bt-srp'; // behaviour targeting

export const MAKE_SRP_AD_TARGETING = {
  sect8: 'makesrp',
};

export const MULTI_SEARCH_SRP_AD_TARGETING = {
  sect8: 'multisrp',
};

export const BT_SRP_AD_TARGETING = {
  sect8: 'btsrp',
};
