import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isFeatureFlagEnabled } from 'site-modules/shared/utils/feature-flags';

/**
 * FeatureFlagContainer component responsible for the `children` rendering depending on feature state
 *
 * @param  {Boolean} options.enabled  True if corresponding feature is enabled
 * @param  {Object}  options.children Children to be rendered
 * @return {ReactElement||Function}   Rendered `children` component
 */
export function FeatureFlagContainer({ enabled, children }) {
  // first check if children is a function, meaning render props pattern is used
  // https://reactjs.org/docs/render-props.html#using-props-other-than-render
  // if so pass enabled value to that function as rendering is delegated to it
  if (typeof children === 'function') {
    return children(enabled);
  }

  // otherwise render nothing if feature is disabled
  if (!enabled) return null;

  // or render passed component if feature is enabled
  return children;
}

/**
 * Maps app redux state to the component properties
 * Defines if the target feature is enabled or not
 *
 * @param  {Object} state    App redux state
 * @param  {Object} ownProps Own component properties
 * @return {Object}          Mapped properties
 */
export const mapStateToProps = (state, ownProps) => {
  const { name } = ownProps;
  const enabled =
    name instanceof Array
      ? name.map(ffName => isFeatureFlagEnabled(state, ffName)).reduce((acc, ffVal) => acc && ffVal) // => true if all feature flags are true
      : isFeatureFlagEnabled(state, name);

  return {
    enabled,
  };
};

/**
 * FeatureFlag component renders children depending on their type and whether feature is enabled or not
 * Connects underlying FeatureFlagContainer component to app redux state
 *
 * @example
 * ```
 * // feature is on
 * <FeatureFlag name="myNewFeature">
 *   <ComponentA />
 * </FeatureFlag>
 * // OR
 * <FeatureFlag name="myNewFeature">
 *   {enabled => enabled
 *     && <ComponentA />
 *   }
 * </FeatureFlag>
 *
 * // feature is off
 * <FeatureFlag name="myNewFeature">
 *   {enabled => !enabled
 *     && <ComponentA />
 *   }
 * </FeatureFlag>
 *
 * // ternary
 * <FeatureFlag name="myNewFeature">
 *   {enabled => enabled
 *     ? <ComponentA />
 *     : <ComponentB />
 *   }
 * </FeatureFlag>
 *
 * // render if both feature flags are true
 * <FeatureFlag name={['thirdparty', 'ads']}>
 *   <MyThirdPartyAdComponent />
 * </FeatureFlag>
 * // OR
 * <FeatureFlag name={['thirdparty', 'ads']}>
 *   {enabled => enabled
 *     && <MyThirdPartyAdComponent />
 *   }
 * </FeatureFlag>
 * ```
 *
 * @type {ReactComponent}
 */
export const FeatureFlag = connect(mapStateToProps)(FeatureFlagContainer);

/**
 * FeatureFlag component prop types
 * @type {Object}
 */
FeatureFlag.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
};
