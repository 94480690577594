import PropTypes from 'prop-types';
import { VehicleEntities } from 'client/data/models/vehicle';

export const VERTICAL_ALIGN = {
  TOP: 'top',
  CENTER: 'center',
  BOTTOM: 'bottom',
};

/**
 * Common props for all ads components
 * Example of use placeholder

 function PlaceholderAd() {
    return <div>Advertisement placeholder</div>;
 }
 <ComponentAd
    placeholderComponent={PricingPlaceholderAd}
    placeholderComponentProps={...pricingPlaceholderAdProps}
 />
 */
export const CommonAdPropTypes = {
  mobile: PropTypes.bool,
  position: PropTypes.string,
  slotRenderEndListener: PropTypes.func,
  placeholderComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  placeholderComponentProps: PropTypes.shape({}),
  customTargeting: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.array])
  ),
};

export const CommonAdDefaultProps = {
  mobile: false,
  position: '1',
  slotRenderEndListener: null,
  placeholderComponent: null,
  placeholderComponentProps: null,
  customTargeting: {},
};

/**
 * common ad site served component props
 * @see BuildPriceAd, PricingAd and etc.
 */
export const AdComponentPropTypes = {
  ...CommonAdPropTypes,
  vehicle: VehicleEntities.Vehicle,
  currentVehicle: VehicleEntities.Vehicle,
  dependedOnCurrentVehicle: PropTypes.bool,
  wrapperClass: PropTypes.string,
  siteServedAdsEnabled: PropTypes.bool,
  // breakpoints
  breakpoints: PropTypes.shape({
    xs: PropTypes.bool,
    sm: PropTypes.bool,
    md: PropTypes.bool,
    lg: PropTypes.bool,
    xl: PropTypes.bool,
  }),
  ivtClassName: PropTypes.string,
};

export const AdComponentDefaultProps = {
  ...CommonAdDefaultProps,
  vehicle: null,
  currentVehicle: null,
  dependedOnCurrentVehicle: false,
  position: '0',
  wrapperClass: '',
  siteServedAdsEnabled: true,
  breakpoints: null,
  isIvt: 'false',
  ivtClassName: null,
};

const defaultWiredBreakpoints = {
  md: true,
  lg: true,
  xl: true,
};

const defaultMobileBreakpoints = {
  xs: true,
  sm: true,
  mobile: true,
};

export const SiteServedAdPropTypes = {
  adRef: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.func]).isRequired,
  adClickTrackingListener: PropTypes.func.isRequired,
  adLoadTrackingListener: PropTypes.func.isRequired,
  adTrackers: PropTypes.node.isRequired,
  position: PropTypes.string,
  mobile: PropTypes.bool,
  vehicle: VehicleEntities.Vehicle, // vehicle is from page context, it is general case
  currentVehicle: VehicleEntities.Vehicle, // vehicle is from component that is passed directly to ad unit
  isIvt: PropTypes.string,
  ivtClassName: PropTypes.string,
};

export const SiteServedAdDefaultProps = {
  position: '0',
  mobile: false,
  vehicle: null,
  currentVehicle: null,
  isIvt: 'false',
  ivtClassName: null,
};

/**
 * NativeAd Component props
 * @see NativeAd
 */
export const NativeAdPropTypes = {
  ...CommonAdPropTypes,
  refreshDisableWhenZipChanged: PropTypes.bool, // this props applies for site-served ads to avoid extra dfp calls
  siteServedAdsUpdater: PropTypes.number,
  nativeStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  dco: PropTypes.bool,
  adNameWired: PropTypes.string,
  adNameMobile: PropTypes.string,
  wiredBreakpoints: PropTypes.objectOf(PropTypes.bool),
  mobileBreakpoints: PropTypes.objectOf(PropTypes.bool),
  showLabel: PropTypes.bool,
  renderWhenViewable: PropTypes.bool,
  refreshWhenViewable: PropTypes.bool,
  noRefresh: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.bool]),
  wrapperClass: PropTypes.string,
  renderOnScroll: PropTypes.bool,
  wiredOnly: PropTypes.bool,
  mobileOnly: PropTypes.bool,
  isSmall: PropTypes.bool,
  nativeFedTestFlag: PropTypes.bool,
  refreshZones: PropTypes.arrayOf(PropTypes.string),
  useMinHeight: PropTypes.bool,
  altMinHeight: PropTypes.number,
  lazyLoad: PropTypes.bool,
  customSizes: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.array, PropTypes.string])),
  customSizesWired: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.array, PropTypes.string])),
  customSizesMobile: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.array, PropTypes.string])),
  addCustomSizes: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.array, PropTypes.string])),
  addCustomSizesWired: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.array, PropTypes.string])),
  addCustomSizesMobile: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.array, PropTypes.string])),
  currentVehicle: VehicleEntities.Vehicle,
  verticalAlign: PropTypes.oneOf(Object.values(VERTICAL_ALIGN)),
};

export const NativeAdDefaultProps = {
  ...CommonAdDefaultProps,
  refreshDisableWhenZipChanged: false,
  siteServedAdsUpdater: 0,
  adNameWired: 'native',
  adNameMobile: 'mnative',
  wiredBreakpoints: defaultWiredBreakpoints,
  mobileBreakpoints: defaultMobileBreakpoints,
  dco: false,
  showLabel: false,
  renderWhenViewable: false,
  refreshWhenViewable: false,
  noRefresh: true,
  wrapperClass: '',
  renderOnScroll: false,
  wiredOnly: false,
  mobileOnly: false,
  isSmall: false,
  nativeFedTestFlag: false,
  refreshZones: [],
  useMinHeight: false,
  altMinHeight: undefined,
  lazyLoad: false,
  customSizes: undefined,
  customSizesWired: undefined,
  customSizesMobile: undefined,
  addCustomSizes: undefined,
  addCustomSizesWired: undefined,
  addCustomSizesMobile: undefined,
  currentVehicle: null,
  verticalAlign: null,
};

/**
 *  AdUnit Component props
 * @see AdUnit
 */
export const AdUnitPropTypes = {
  ...CommonAdPropTypes,
  refreshDisableWhenZipChanged: PropTypes.bool, // this props applies for site-served ads to avoid extra dfp calls
  enabled: PropTypes.bool,
  adName: PropTypes.string.isRequired,
  nativeStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  dco: PropTypes.bool,
  isVisible: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  // breakpoints
  ads: PropTypes.shape({
    adsts: PropTypes.shape({
      value: PropTypes.number,
      trigger: PropTypes.string,
    }),
    refreshZones: PropTypes.arrayOf(PropTypes.string),
  }),
  xs: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  sm: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  md: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  lg: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  xl: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  all: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  page: PropTypes.shape({
    name: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
  }).isRequired,
  breakpoint: PropTypes.string,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  pageVehicle: VehicleEntities.Vehicle.isRequired,
  currentVehicle: VehicleEntities.Vehicle,
  oneByOne: PropTypes.bool,
  showLabel: PropTypes.bool,
  renderWhenViewable: PropTypes.bool, // TODO: true value cannot be used with renderOnScroll=true, don't make sense
  refreshWhenViewable: PropTypes.bool,
  renderOnScroll: PropTypes.bool, // TODO: true value cannot be used with renderWhenViewable=true, don't make sense
  refreshable: PropTypes.bool,
  noRefresh: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.bool]),
  correlatorGroup: PropTypes.string,
  refreshZones: PropTypes.arrayOf(PropTypes.string),
  customSizes: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.array, PropTypes.string])),
  dfpCollapse: PropTypes.bool,
  visitor: PropTypes.shape({
    location: PropTypes.shape({
      zipCode: PropTypes.string.isRequired,
    }),
    privacy: PropTypes.shape({
      uspString: PropTypes.string,
    }),
  }),
};

export const AdUnitDefaultProps = {
  ...CommonAdDefaultProps,
  refreshDisableWhenZipChanged: false,
  enabled: true,
  nativeStyle: '',
  dco: false,
  // breakpoints
  xs: false,
  sm: false,
  md: false,
  lg: false,
  xl: false,
  all: false,
  isVisible: true,
  ads: {
    adsts: {
      value: '',
      trigger: '',
    },
    tsPerUnits: {},
    refreshZones: [],
  },
  currentVehicle: null,
  oneByOne: false,
  showLabel: true,
  renderWhenViewable: true,
  refreshWhenViewable: false,
  renderOnScroll: false,
  refreshable: true,
  noRefresh: false,
  correlatorGroup: '',
  refreshZones: [],
  customSizes: [],
  dfpCollapse: false,
  visitor: {
    privacy: {
      uspString: '',
    },
  },
};
