/**
 * Returns true if the feature is enabled on the given state.  This utility function is
 * helpful when you need to check a feature flag outside of jsx code (like preload method).
 *
 * @param  {Object} state    App redux state
 * @param  {String} feature  Key
 * @return {boolean}
 */
export function isFeatureFlagEnabled(state, feature) {
  return !!(state.featureFlags && state.featureFlags[feature]);
}
