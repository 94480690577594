import { uniqWith, get, compact } from 'lodash';
import { TRANSACTION_TYPES_MAP } from 'site-modules/shared/constants/incentives';

export function getIncentiveTitle({ subtype, name, transactionTypes, termMonths }) {
  const term = get(transactionTypes, 'length') === 1 && transactionTypes[0] === 'LEASE' && termMonths;
  const suffix = term ? ` - ${term}mo term` : '';

  const title = subtype && subtype !== 'UNKNOWN' ? subtype : name;

  return `${title}${suffix}`;
}

export function getTransactionTypes({ transactionTypes = [] }) {
  return compact(transactionTypes.map(type => TRANSACTION_TYPES_MAP[type]).sort()).join(', ');
}

export function getFormattedIncentives(incentives = []) {
  const sortedIncentives = incentives
    .sort((a, b) => get(a, 'apr', 0) - get(b, 'apr', 0))
    .sort((a, b) => a.endDate - b.endDate);
  const displayIncentives = uniqWith(
    sortedIncentives,
    (a, b) => a.sourceTypeValue === b.sourceTypeValue && a.subprogramId === b.subprogramId
  );

  return { sortedIncentives, displayIncentives };
}

export function getFormattedGraphqlIncentives(incentives = []) {
  return incentives.sort((a, b) => new Date(a.endDate) - new Date(b.endDate));
}
