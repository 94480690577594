/**
 * Checks if given slot is rendering empty ad unit
 * @param slotObj - ad unit slot object
 * @return {boolean}
 */
export const isEmptyUnit = slotObj => slotObj.isEmpty || slotObj.size.isEmpty;

/**
 * Checks if given slot is rendering 1x1 pixel ad unit
 * @param slotObj - ad unit slot object
 * @return {boolean}
 */
export const isPixelUnit = slotObj => !slotObj.size.isEmpty && (slotObj.size[0] === 1 && slotObj.size[1] === 1);

export const isRenderedAdUnit = slotObj => !(isEmptyUnit(slotObj) || isPixelUnit(slotObj));
