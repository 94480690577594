import { get } from 'lodash';
import { getQuery } from 'client/utils/location';

/**
 * Get dealerRooftopId from one of the various props (preferred)/state/page root sources,
 *  including dealerLocationId variants.
 * Examples:
 * props.location.query.dealerlocationid => rootSource = props
 * params.dealerRooftopId => rootSource = params
 * @param {Object} rootSource
 * @returns {String}
 */
export function getDealerRooftopId(rootSource) {
  const location = get(rootSource, 'location') || get(rootSource, 'pageContext.location') || {};
  return (
    getQuery(location).dealerlocationid ||
    get(rootSource, 'vinData.dealerInfo.rooftopId') ||
    get(rootSource, 'dealerInfo.rooftopId') ||
    /* Legacy Edmunds Express Sources: */
    get(rootSource, 'params.dealerRooftopId') ||
    get(rootSource, 'pageContext.dealer.rooftopId')
  );
}
