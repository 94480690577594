import { isEmpty, omit } from 'lodash';
import { PAGE_NUMBER, RADIUS } from 'client/site-modules/shared/constants/allowed-inventory-request-params';
import { getUrlPattern } from 'site-modules/shared/utils/inventory/srp-utils';
import * as URL_PATTERNS from 'site-modules/shared/constants/inventory/srp-url-patterns';

const LEASE_DEALS_PATTERN = 'lease-deals';

/**
 * Checks whether SRP is national based on URL context.
 * @param {object} urlContext
 * @returns {boolean}
 */
export function isNationalSRP(urlContext) {
  return !!(urlContext && urlContext.attributes && urlContext.attributes.nationalScope);
}

/**
 * Checks whether SRP is lease based on URL context.
 * @param {object} urlContext
 * @returns {boolean}
 */
export function isLeaseSRP(urlContext) {
  const urlPattern = getUrlPattern(urlContext);
  return urlPattern.includes(LEASE_DEALS_PATTERN);
}

/**
 * Checks whenever SRP has rooftopId in selected facets or not.
 * @param {object} selectedFacets
 * @returns {boolean}
 */
export function isDealerSRP(selectedFacets) {
  return !!(selectedFacets && selectedFacets.rooftopId && selectedFacets.rooftopId.length);
}

/**
 * Determine if page is on used-cheap-cars-for-sale path.
 * @param {string} urlPattern
 * @returns {boolean}
 */
export function isUsedCheapCarsSRP(urlPattern) {
  return urlPattern === 'used-cheap-cars-for-sale';
}

/**
 * Determine if page is on cheap-lease-dealse path.
 * @param {string} urlPattern
 * @returns {boolean}
 */
export function isCheapLeaseSRP(urlPattern) {
  return urlPattern === 'cheap-lease-deals';
}

/**
 * Checks whenever SRP has used-year-make-model-city-state url.
 * @param {string} urlPattern
 * @returns {boolean}
 */
export function isUsedYMMCityStateSRP(urlPattern) {
  return urlPattern === 'used-year-make-model-city-state';
}

/**
 * Checks whenever SRP has make-model-lease-deals url.
 * @param {string} urlPattern
 * @returns {boolean}
 */
export function isMakeModelLeaseDeals(urlPattern) {
  return urlPattern === 'make-model-lease-deals';
}

/**
 * Checks whenever SRP is matching flat NEW URL path (e.g. new-cars-for-sale, new-honda-civic-for-sale)
 * @param {string} urlPattern
 * @returns {boolean}
 */
export function isNewFlatUrlSRP(urlPattern) {
  return /^new-(\w+-){1,2}for-sale.*$/.test(urlPattern);
}

/**
 * Checks whenever SRP is matching flat new-make-model-for-sale URL path
 * @param {string} urlPattern
 * @returns {boolean}
 */
export function isNewMakeModelSRP(urlPattern) {
  return urlPattern === URL_PATTERNS.NEW_MAKE_MODEL_URL_PATTERN;
}

/**
 * Checks whenever SRP has used-make-model url.
 * @param {string} urlPattern
 * @returns {boolean}
 */
export function isUsedMakeModelSRP(urlPattern) {
  return urlPattern === 'used-make-model';
}

/**
 * Checks whenever SRP has used-make-model url (e.g. used-cars-under-5000).
 * @param {string} urlPattern
 * @returns {boolean}
 */
export function isUsedCarsUnderPriceSRP(urlPattern) {
  return urlPattern === 'used-cars-under-price';
}

/**
 * Checks whenever SRP has used-all url.
 * @param {string} urlPattern
 * @returns {boolean}
 */
export function isUsedAllSRP(urlPattern) {
  return urlPattern === URL_PATTERNS.USED_ALL;
}

/**
 * Checks whenever SRP has cars-for-sale-by-owner url.
 * @param {string} urlPattern
 * @returns {boolean}
 */
export function isUsedByOwnerSRP(urlPattern) {
  return urlPattern === URL_PATTERNS.CARS_FOR_SALE_BY_OWNER;
}

/**
 * Checks whenever SRP has make-dealership url.
 * @param {string} urlPattern
 * @returns {boolean}
 */
export function isMakeDealershipSRP(urlPattern) {
  return urlPattern === URL_PATTERNS.MAKE_DEALERSHIP;
}

/**
 * Checks whenever SRP has make-dealership-city-state url.
 * @param {string} urlPattern
 * @returns {boolean}
 */
export function isMakeDealershipCityStateSRP(urlPattern) {
  return urlPattern === URL_PATTERNS.MAKE_DEALERSHIP_CITY_STATE;
}

/**
 * @param {string} urlPattern
 * @returns {boolean}
 */
export function isUsedCarsStateSRP(urlPattern) {
  return urlPattern === URL_PATTERNS.USED_CARS_STATE;
}

/**
 * @param {string} urlPattern
 * @returns {boolean}
 */
export function isMakeModelLeaseDealsLocationSRP(urlPattern) {
  return [URL_PATTERNS.MAKE_MODEL_LEASE_DEALS_STATE, URL_PATTERNS.MAKE_MODEL_LEASE_DEALS_CITY_STATE].includes(
    urlPattern
  );
}

/**
 * Checks whenever SRP is a car finder (has no search params).
 * @param {object} filters
 * @returns {boolean}
 */
export function isCarFinderSRP(filters) {
  return isEmpty(omit(filters, [PAGE_NUMBER]));
}

/**
 * Checks whenever all SRP filters were cleared (has no search params except for radius).
 * @param {object} filters
 * @returns {boolean}
 */
export function isClearAllFilters(filters) {
  return isEmpty(omit(filters, [RADIUS]));
}

/**
 * Determines that the SRP is USED Core page.
 * @param {object} urlContext
 * @returns {boolean}
 */
export function isSrpOnUsedCorePage(urlContext) {
  return !!(urlContext && urlContext.isSrpOnUsedCore);
}

/**
 * Checks if url pattern is /make/model/year/submodel/
 * Ex: /ford/f-150/2021/raptor/
 * @param {string} urlPattern
 * @returns {boolean}
 */
export function isCoreSrpMMYS(urlPattern) {
  return urlPattern === URL_PATTERNS.CORE_SRP_MMYS;
}

/**
 * Checks if url pattern is make/model/year/for-sale
 * Ex: honda/accord/2023/for-sale
 * @param urlPattern
 * @returns {boolean}
 */
export function isForSaleSRP(urlPattern) {
  return urlPattern === URL_PATTERNS.SRP_FOR_SALE;
}
