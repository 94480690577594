import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { get, orderBy } from 'lodash';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { EdmundsAPI } from 'client/data/api/api-client';
import { EdmundsGraphQLFederation } from 'client/data/graphql/graphql-client';
import { VehicleModel } from 'client/data/models/vehicle';
import { VisitorModel } from 'client/data/models/visitor';
import { withMetrics } from 'client/data/api/api-metrics';
import { getTypeNameBySlug } from 'client/site-modules/shared/utils/query-type-mapping';
import { INCENTIVE_CATEGORY } from 'site-modules/shared/constants/incentives';

const Incentive = PropTypes.shape({
  id: PropTypes.number,
  startDate: PropTypes.number,
  endDate: PropTypes.number,
  comments: PropTypes.string,
  restrictions: PropTypes.string,
  name: PropTypes.string,
  rebateAmount: PropTypes.number,
  primary: PropTypes.bool,
  termMonths: PropTypes.number,
  apr: PropTypes.number,
  dealerCashAmount: PropTypes.number,
  sourceTypeValue: PropTypes.string,
  sourceType: PropTypes.string,
  userMessaging: PropTypes.string,
});

const IncentiveGraphql = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  subtype: PropTypes.string,
  subtypeId: PropTypes.number,
  restrictions: PropTypes.string,
  details: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  rebateAmount: PropTypes.number,
  primary: PropTypes.bool,
  categories: PropTypes.arrayOf(PropTypes.shape({ category: PropTypes.string })),
  aprs: PropTypes.arrayOf(
    PropTypes.shape({
      apr: PropTypes.number,
      termMonth: PropTypes.number,
    })
  ),
  termMonths: PropTypes.number,
  transactionTypes: PropTypes.arrayOf(PropTypes.string),
  percentOffMSRP: PropTypes.number,
});

const IncentiveByCategory = PropTypes.shape({
  rebatesIncentive: Incentive,
  financingIncentive: Incentive,
  leasingIncentive: Incentive,
  dealerIncentive: Incentive,
  otherIncentive: Incentive,
  modelYear: PropTypes.shape({
    id: PropTypes.number,
    modelName: PropTypes.string,
    year: PropTypes.number,
    makeName: PropTypes.string,
  }),
});

const SpecificIncentives = PropTypes.arrayOf(Incentive);
const SpecificIncentivesGraphql = PropTypes.arrayOf(IncentiveGraphql);

export const IncentivesEntities = {
  Incentives: PropTypes.shape({
    numIncentives: PropTypes.number,
    bonus: SpecificIncentives,
    financing: SpecificIncentives,
    lease: SpecificIncentives,
    other: SpecificIncentives,
  }),
  IncentivesGraphql: PropTypes.shape({
    numIncentives: PropTypes.number,
    bonus: SpecificIncentivesGraphql,
    financing: SpecificIncentivesGraphql,
    lease: SpecificIncentivesGraphql,
    other: SpecificIncentivesGraphql,
  }),
  Incentive,
  IncentiveGraphql,
  IncentiveByCategory,
  SpecificIncentives,
  SpecificIncentivesGraphql,
};

function getIncentives(apiCarIncentives, key) {
  const subCategoryMap =
    (apiCarIncentives.categoryMap &&
      apiCarIncentives.categoryMap[key] &&
      apiCarIncentives.categoryMap[key].subCategories &&
      apiCarIncentives.categoryMap[key].subCategories.subCategoryMap) ||
    {};

  return Object.values(subCategoryMap).reduce((accumulator, subcategory) => {
    if (subcategory.incentives && subcategory.incentives.length) {
      accumulator.push(...subcategory.incentives);
    }
    return accumulator;
  }, []);
}

function transformIncentives(apiCarIncentives) {
  const carIncentives = {
    bonus: getIncentives(apiCarIncentives, 'REBATES'),
    financing: getIncentives(apiCarIncentives, 'FINANCING'),
    lease: getIncentives(apiCarIncentives, 'LEASING'),
    other: getIncentives(apiCarIncentives, 'OTHER'),
  };

  carIncentives.numIncentives =
    carIncentives.bonus.length +
    carIncentives.financing.length +
    carIncentives.lease.length +
    carIncentives.other.length;

  return carIncentives;
}

function transformGraphqlIncentives(graphqlCarIncentives) {
  const carIncentives = {
    bonus: graphqlCarIncentives.filter(
      ({ categories }) => get(categories, '[0].category') === INCENTIVE_CATEGORY.REBATES
    ),
    financing: graphqlCarIncentives.filter(
      ({ categories }) => get(categories, '[0].category') === INCENTIVE_CATEGORY.FINANCING
    ),
    lease: graphqlCarIncentives.filter(
      ({ categories }) => get(categories, '[0].category') === INCENTIVE_CATEGORY.LEASING
    ),
    other: graphqlCarIncentives.filter(
      ({ categories }) => get(categories, '[0].category') === INCENTIVE_CATEGORY.OTHER
    ),
  };

  carIncentives.numIncentives =
    carIncentives.bonus.length +
    carIncentives.financing.length +
    carIncentives.lease.length +
    carIncentives.other.length;

  return carIncentives;
}

function groupIncentivesByMake(incentives) {
  const sortedIncentives = orderBy(
    incentives,
    ['modelYear.makeName', 'modelYear.modelName', 'modelYear.year'],
    ['asc', 'asc', 'desc']
  );

  return sortedIncentives.reduce((result, incentive) => {
    const key = get(incentive, 'modelYear.makeName');
    if (!result[key]) {
      result[key] = []; // eslint-disable-line
    }
    result[key].push(incentive);

    return result;
  }, {});
}

const getIncentiveAPIUrlByStyleId = (styleId, zip) => `/incentives/v2?zip=${zip}&styles=${styleId}&groupby=CATEGORY`;

export const getIncentivesPath = (styleId, isGraphQl) =>
  styleId ? `${isGraphQl ? 'graphql.' : ''}styles.${styleId}.incentives` : null;

export const getPrimaryIncentivesPath = styleId => styleId && `styles.${styleId}.primaryIncentives`;

export const getTaxCreditAmountPath = styleId => styleId && `styles.${styleId}.taxCreditAmount`;

export const getMmyTaxCreditAmountPath = ({ make, model, year }) =>
  `makes["${make}"].models["${model}"].years["${year}"].taxCreditAmount`;

export const getEvTaxCreditAmountPath = (styleId, isUsed) =>
  styleId && `styles.${styleId}.pubStates.${isUsed ? 'USED' : 'NEW'}.evTaxCreditAmount`;

export const getIncentivesPathWithCustomZip = styleId => (styleId ? `styles.${styleId}.incentives.customZip` : null);

export const buildIncentivesPath = type => `types.${type}.incentives`;

export const IncentiveModel = createModelSegment('incentivesData', [
  {
    /**
     * Incentives for vehicle MMY SubmodelId
     * example api call: http://www.edmunds.com/api/incentives/v2?zip=90068&submodelId=401698763&groupby=CATEGORY&includeStyleTrim=true&type=CASH_REBATE%2CLEASE%2CLOW_APR%2CMISCELLANEOUS
     */
    path: 'makes["{make}"].models["{model}"].submodels["{submodel}"].years["{year}"].incentives',
    resolve(match, context) {
      return context.resolveValue('location.zipCode', VisitorModel).then(zipCode =>
        context
          .resolveValue(
            `makes["${match.make}"].models["${match.model}"].submodels["${match.submodel}"].years["${match.year}"].id`,
            VehicleModel
          )
          .then(defaultSubmodelId => {
            const apiUrl = `/incentives/v2?zip=${zipCode}&submodelId=${defaultSubmodelId}&groupby=CATEGORY&includeStyleTrim=true&type=CASH_REBATE%2CLEASE%2CLOW_APR%2CMISCELLANEOUS`;
            return withMetrics(EdmundsAPI, context)
              .fetch(apiUrl)
              .then(response => response.json())
              .then(transformIncentives);
          })
      );
    },
  },
  {
    path: 'incentivesZip',
  },
  {
    /**
     * Incentives for vehicle's StyleId.
     * Example api call: https://qa-21-www.edmunds.com/gateway/api/incentives/v2?zip=94043&styles=401725647&groupby=CATEGORY
     * @see getIncentivesPathWithCustomZip
     */
    path: 'styles.{styleId}.incentives.customZip',
    async resolve(match, context) {
      const incentivesZipObject = await context.resolveValue('incentivesZip');
      const zip = get(incentivesZipObject, 'zip');

      if (!zip) {
        return null;
      }

      const { styleId } = match;
      const apiUrl = getIncentiveAPIUrlByStyleId(styleId, zip);
      return withMetrics(EdmundsAPI, context)
        .fetchJson(apiUrl)
        .then(transformIncentives);
    },
  },
  {
    /**
     * Incentives for vehicle's StyleId.
     * Example api call: https://qa-21-www.edmunds.com/gateway/api/incentives/v2?zip=94043&styles=401725647&groupby=CATEGORY
     */
    path: 'styles.{styleId}.incentives',
    resolve(match, context) {
      return context.resolveValue('location.zipCode', VisitorModel).then(zipCode => {
        const { styleId } = match;
        const apiUrl = getIncentiveAPIUrlByStyleId(styleId, zipCode);
        return withMetrics(EdmundsAPI, context)
          .fetchJson(apiUrl)
          .then(transformIncentives);
      });
    },
  },
  {
    /**
     * Makes that have incentives.
     * Example api call: https://qa-21-www.edmunds.com/api/incentives/v3/makes/availability?zip=90404
     */
    path: 'makes',
    async resolve(match, context) {
      const zipCode = await context.resolveValue('location.zipCode', VisitorModel);
      const apiUrl = `/incentives/v3/makes/availability?zip=${zipCode}`;
      const results = await withMetrics(EdmundsAPI, context).fetchJson(apiUrl);
      return get(results, 'makes', []);
    },
  },
  {
    /**
     * Vehicle Types that have incentives.
     * Example api call: https://qa-21-www.edmunds.com/api/incentives/v3/vehicletypes/availability?zip=90404
     */
    path: 'vehicleTypes',
    async resolve(match, context) {
      const zipCode = await context.resolveValue('location.zipCode', VisitorModel);
      const apiUrl = `/incentives/v3/vehicletypes/availability?zip=${zipCode}`;
      const results = await withMetrics(EdmundsAPI, context).fetchJson(apiUrl);
      return get(results, 'vehicleTypes', []);
    },
  },
  {
    /**
     * Incentives for make of specific type.
     * Example api call: https://www.edmunds.com/api/incentives/v2?category=Hybrid&zip=90409&groupby=CATEGORY_AND_MODELYEAR&groupByNewModelYears=true
     */
    path: 'types.{type}.incentives',
    resolve(match, context) {
      const { type } = match;
      return context.resolveValue('location.zipCode', VisitorModel).then(zipCode => {
        const apiUrl = `/incentives/v2?zip=${zipCode}&category=${getTypeNameBySlug(
          type
        )}&groupby=CATEGORY_AND_MODELYEAR&groupByNewModelYears=true&view=basic`;
        return withMetrics(EdmundsAPI, context)
          .fetchJson(apiUrl)
          .then(groupIncentivesByMake);
      });
    },
  },
  {
    /** TODO: When non-grahpql path will be removed - suppress 'grahpql'
     * Incentives for vehicle's StyleId.
     *
     * @see getIncentivesPath
     * @returns IncentivesEntities.IncentivesGraphql
     */
    path: 'graphql.styles.{styleId}.incentives',
    async resolve({ styleId }, context) {
      const zipCode = await context.resolveValue('location.zipCode', VisitorModel);

      return withMetrics(EdmundsGraphQLFederation, context)
        .query(
          gql`
            query($styleId: String!, $zipCode: String!) {
              incentives(incentiveFilters: { zip: $zipCode, styleIds: [$styleId], inventoryCodes: [NEW] }) {
                incentives {
                  id
                  name
                  type
                  subtype
                  subtypeId
                  restrictions
                  details
                  startDate
                  endDate
                  rebateAmount
                  categories {
                    category
                  }
                  aprs(creditRatingTier: TIER1) {
                    apr
                    termMonths
                  }
                  transactionTypes
                  termMonths
                  percentOffMSRP
                  primary
                }
              }
            }
          `,
          {
            zipCode,
            styleId,
          }
        )
        .then(({ incentives }) => transformGraphqlIncentives(incentives.incentives));
    },
  },
  {
    /**
     * Primary incentives for vehicle's StyleId.
     *
     * @see getPrimaryIncentivesPath
     */
    path: 'styles.{styleId}.primaryIncentives',
    async resolve({ styleId }, context) {
      const zipCode = await context.resolveValue('location.zipCode', VisitorModel);

      return withMetrics(EdmundsGraphQLFederation, context)
        .query(
          gql`
            query($styleId: String!, $zipCode: String!) {
              incentives(incentiveFilters: { zip: $zipCode, styleIds: [$styleId] }) {
                incentives {
                  id
                  name
                  subtype
                  endDate
                  rebateAmount
                  restrictions
                  primary
                }
              }
            }
          `,
          {
            zipCode,
            styleId,
          }
        )
        .then(({ incentives }) => incentives.incentives.filter(({ primary }) => primary));
    },
  },
  /**
   * @see getTaxCreditAmountPath
   */
  {
    path: 'styles.{styleId}.taxCreditAmount',
    async resolve({ styleId }, context) {
      const zipCode = await context.resolveValue('location.zipCode', VisitorModel);

      return withMetrics(EdmundsGraphQLFederation, context)
        .query(
          gql`
            query($styleId: String!, $zipCode: String!) {
              incentives(
                incentiveFilters: {
                  zip: $zipCode
                  styleIds: [$styleId]
                  subtypeIds: { in: [517, 640] }
                  inventoryCodes: [NEW]
                }
              ) {
                incentives {
                  rebateAmount
                }
              }
            }
          `,
          {
            zipCode,
            styleId,
          }
        )
        .then(({ incentives }) =>
          incentives.incentives.reduce((total, { rebateAmount }) => total + (rebateAmount || 0), 0)
        );
    },
  },
  /**
   * @see getMmyTaxCreditAmountPath
   */
  {
    path: 'makes["{make}"].models["{model}"].years["{year}"].taxCreditAmount',
    async resolve({ make, model, year }, context) {
      const zipCode = await context.resolveValue('location.zipCode', VisitorModel);

      return withMetrics(EdmundsGraphQLFederation, context)
        .query(
          gql`
            query($make: String!, $model: String!, $year: Int!, $zipCode: String!) {
              incentives(
                incentiveFilters: {
                  zip: $zipCode
                  makeSlugs: { in: [$make] }
                  modelSlugs: { in: [$model] }
                  years: { in: [$year] }
                  subtypeIds: { in: [517, 640] }
                }
              ) {
                incentives {
                  rebateAmount
                }
              }
            }
          `,
          {
            zipCode,
            make,
            model,
            year: parseInt(year, 10),
          }
        )
        .then(({ incentives }) =>
          get(incentives, 'incentives', []).reduce((total, { rebateAmount }) => total + (rebateAmount || 0), 0)
        );
    },
  },
  /**
   * @see getEvTaxCreditAmountPath
   */
  {
    path: 'styles.{styleId}.pubStates.{pubState}.evTaxCreditAmount',
    async resolve({ styleId, pubState }, context) {
      const zipCode = await context.resolveValue('location.zipCode', VisitorModel);

      return withMetrics(EdmundsGraphQLFederation, context)
        .query(
          gql`
            query($styleId: String!, $zipCode: String!, $inventoryCode: InventoryCode!) {
              incentives(
                incentiveFilters: {
                  zip: $zipCode
                  styleIds: [$styleId]
                  programTypesFilter: {
                    in: [
                      "EV Rebate"
                      "EV Charging"
                      "Tax Credit"
                      "Utility Rebate"
                      "Municipality Rebate"
                      "Charger Installation"
                      "State Rebate"
                    ]
                  }
                  types: { notIn: [CUSTOMER_APR, DEALER_CASH] }
                  transactionTypes: { in: [CASH] }
                  inventoryCodes: [$inventoryCode]
                }
              ) {
                incentives {
                  rebateAmount
                }
              }
            }
          `,
          {
            zipCode,
            styleId,
            inventoryCode: pubState,
          }
        )
        .then(({ incentives }) =>
          get(incentives, 'incentives', []).reduce((total, { rebateAmount }) => total + (rebateAmount || 0), 0)
        );
    },
  },
]);
