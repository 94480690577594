import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

/**
 * Component that allows define recipe of the multivariant experiment.
 */
export class Recipe extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    // eslint-disable-next-line react/no-unused-prop-types
    name: PropTypes.string.isRequired, // name is not referenced here but needed by <Experiment />
  };
  static defaultProps = {
    children: null,
  };
  render() {
    return <Fragment>{this.props.children}</Fragment>;
  }
}
